import React,{Fragment} from 'react';
import {connect} from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import NavBar from './components/layout/NavBar';
import NavBarLoggedIn from './components/layout/NavBarLoggedIn';

// helpers 
import { history } from './_helpers'
// component
import Logout from './components/Logout';
import Home from './components/Home';
import Policies from './components/Policies';
import Faq from './components/Faq';
import IMMI from './components/IMMI';
import BookAppointment from './components/BookAppointment';
import Footer from './components/layout/Footer';
import FooterLoggedIn from './components/layout/FooterLoggedIn'
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import Messages from './components/messages';
import Message from './components/message';
import { ReplyMessage } from './components/ReplyMessage';
import MessageAuth from './components/MessageAuth';
import ForgotPassword from './components/ForgotPassword';
import NotFound from './components/layout/NotFound';
import MyAccount from './components/MyAccount';
import SickNote from './components/sick-note';
import Loader from './components/layout/Loader';
import RouteChangeTracker from './components/layout/RouteChangeTracker';
import { SicknoteMedicalInfo } from './MedicalInfo/SicknoteMedicalInfo';
import { EditMedicalInfoPage } from './MedicalInfo/EditMedicalInfoPage';
import MedicalRecordsDownload from './components/file/medicalRecordsDownload';
// routes
import PublicRoute from './components/layout/PublicRoute';
import PrivateRoute from './components/layout/PrivateRoute';
import { Payment } from './components/payment';



import axios from 'axios';
import IdleTimer from 'react-idle-timer';

import store from './store';
import {logoutAction, loadUser} from './actions/auth';
import {getAllPharmacy} from './actions/pharmacy';
import config from './config';
import { setLang as setLangAction } from "./actions/lang";
import { setLang as setLangService } from "./services/lang";
import {setToLoading} from './actions/loader';

axios.interceptors.response.use(response => {
  // console.log(response)
  return response;
},(err)=>{

  if(err.response && err.response.data.type == "tokenNotValid"){
      setTimeout(()=>{
          // console.log('redirect to login')
          store.dispatch(logoutAction())

      },1000)
      return Promise.reject(err);
      
  }else{
      return Promise.reject(err);
  }
  
})

class App extends React.Component {

  constructor(props) {
    super(props);
    this.idleTimer=null;  
    this.onIdle = this.onIdle.bind(this);

    const lang = new URL(window.location.href).searchParams.get("lang");
    if (lang) {
      store.dispatch(setLangAction(lang));
      setLangService(lang);
      history.push(window.location.pathname)
      // window.location.replace("http://www.w3schools.com")
    }
  }
  
  onIdle(){
    console.log('idle handler');
    this.props.logoutAction();
  }

  componentDidMount(){

    //@TODO: limit the origin on production
    //  /localhost:8080$/
    //  /localhost:6001$/
    
    // CrossStorageHub.init([
    //   {origin: /.*/, allow: ['get', 'set', 'del', 'getKeys', 'clear']}
    // ]);
    store.dispatch(loadUser())
    store.dispatch(getAllPharmacy())

  }
  
  render() {
    const {isAuthenticated} = this.props;
    return(
      //@TODO
      // Add public and private routing
        <Fragment>
              <IdleTimer
                  ref={ref => { this.idleTimer = ref; }}
                  element={document}
                  onIdle={this.onIdle}
                  timeout={1000 * 60 * 5} //1000 * 60 * 5
              />
              <Loader message="Please be patient. This may take a while."/>
            <Router history={history}>
                {(config.service == "https://api.instoremed.com") ? <RouteChangeTracker /> : null }
                {!isAuthenticated ? <Route path="/" component={NavBar} /> : 
                <Route path="/" component={NavBarLoggedIn} /> }

                <Switch>
                  <PublicRoute exact path="/" component={Home} />
                  <Route exact path="/policies" component={Policies} />
                  <Route exact path="/faq" component={Faq} />
                  <Route exact path="/immigration" component={IMMI} />
                  <Route exact path="/bookappointment" component={BookAppointment} />
                  <Route exact path="/login" component={Login} />
                  <Route path="/logout" component={Logout} />
                  <Route exact path="/login-cn" component={Login} />
                  <Route exact path="/register" component={Register} />
                  <Route exact path="/forgotpassword" component={ForgotPassword} />
                  <Route path='/BOOKONLINE' component={() => { 
                    this.props.setToLoading();
                    window.location.href = config.immigration_url; 
                    return null;
                  }}/>
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute exact path="/message" component={Messages} />
                  <PrivateRoute exact path="/message/:id" component={Message} />
                  <PrivateRoute exact path="/account" component={MyAccount} />
                  <PrivateRoute exact path='/medicalInfo' component={SicknoteMedicalInfo} />
                  <PrivateRoute exact path='/medicalInfo/edit' component={EditMedicalInfoPage} />
                  <PrivateRoute exact path="/sick-note" component={SickNote} />
                  <PrivateRoute exact path="/sick-note/payment" component={Payment} />
                  <Route  path="/auth-message/:token" component={MessageAuth} />
                  <Route  path="/message-reply" component={ReplyMessage} />
                  <Route path="/medical/records/:token" component={MedicalRecordsDownload} />
                  <Route component={NotFound} />

                </Switch>

                {!isAuthenticated ? <Route path="/" component={Footer} /> : <Route path="/" component={FooterLoggedIn} />}
            </Router>
        </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
})

const mapDispatchToProps = {
  logoutAction,
  setToLoading,
  getAllPharmacy
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
