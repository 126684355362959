import {
    SET_LANG
} from '../actions/type';
import { getLang } from "../services/lang";

const initialState = {lang: getLang()}

export default (state=initialState,action) => {
    const {type , payload} = action;
    
    switch(type){
        case SET_LANG:
            return {
                ...state,
                lang: payload
            }            
        default:
            return state;
    }

}


