import {
    SET_ALERT,
    REMOVE_ALERT
} from '../actions/type';

const initialState = {alert:[]}

export default (state=initialState,action) => {

    const {type,payload} = action;
    
    switch(type){
        case SET_ALERT:
            return {
                ...state,
                    alert:[...state.alert,payload]
                    };
        case REMOVE_ALERT:
            return {
                ...state,
                alert:state.alert.filter(e=>e.id!==payload)
            }
            
        default:
            return state;
    }

}


