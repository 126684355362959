import React, { Fragment,useRef } from 'react';
import {connect} from 'react-redux';
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";

// helpers
import {history} from '../../_helpers';

import {logoutAction} from '../../actions/auth'
import { toggleLang } from '../../actions/lang';
import { switchLang } from "../../services/lang";
import AlertBox from "../../components/layout/Alert";

const NavBarLoggedIn = (props) => {

    const linksRef = useRef();
    const closeRef = useRef();

    const linkClickHandler = (e) => {
        toggleNavBar();
        history.push(e.target.name)
    }

    const toggleNavBar = () => {

        // linksRef.current.style.display = "block"
        linksRef.current.childNodes.forEach(node => {
            if(!node.style.display || node.style.display=="none")
                node.style.display = "block";
            else if(node.style.display=="block")
                node.style.display= "none";
        })

        if(!closeRef.current.style.display || closeRef.current.style.display=='none'){
            closeRef.current.style.display = 'block'
        }else if(closeRef.current.style.display=='block'){
            closeRef.current.style.display='none'
        }
    }

    const {location, user} = props;

    if(location.pathname.includes('/medical/records')){
        return null;
    }

    return (

        <Fragment>
            <nav id="main-nav">
                <div className="logo">
                    <Link to="/" name="/" onClick={linkClickHandler}>Richmond In-Store Medical Clinic <i className="fas fa-hospital"></i></Link>
                    {user ? <span className="user-name">
                              {user.firstName} {user.lastName}
                            </span> : 
                            <span></span>}
                </div>
                <ul>
                    <div className="nav-bars-icon">
                        <a href="#" onClick={toggleNavBar}><i className="fas fa-bars"></i></a>

                        <a ref={closeRef} className="pull-right close-icon" href="#" onClick={toggleNavBar}><i className="fas fa-times"></i></a>
                    </div>

                    <section ref={linksRef} id="nav-bar-links" className="nav-bar-links" >

                        <li ><Link name="/" className={location.pathname == '/dashboard' ? 'current' :''} to="/" onClick={linkClickHandler} >
                            <Translation>
                                {
                                    t => <>{t('DASHBOARD')}</>
                                }
                            </Translation>
                        </Link></li>
                        <li ><Link name="/account" className={location.pathname == '/account' ? 'current' :''} to="/account" onClick={linkClickHandler} >
                            <Translation>
                                {
                                    t => <>{t('MY ACCOUNT')}</>
                                }
                            </Translation>
                        </Link></li>
                        <li ><Link name="/message" className={location.pathname == '/message' ? 'current' :''} to="/message" onClick={linkClickHandler} >
                            <Translation>
                                {
                                    t => <>{t('MY MESSAGES')}</>
                                }
                            </Translation>
                        </Link></li>
                        {/* <li ><Link name="/sick-note" className={location.pathname == '/account' ? 'current' :''} to="/sick-note" onClick={linkClickHandler} >
                            <Translation>
                                {
                                    t => <>{t('SICK NOTE')}</>
                                }
                            </Translation>
                        </Link></li> */}
                        {/* <li ><Link name="/medicalInfo" className={location.pathname == '/sick-note' ? 'current' :''} to="/medicalInfo" onClick={linkClickHandler} >
                            <Translation>
                                {
                                    t => <>{t('Sick Note')}</>
                                }
                            </Translation>
                        </Link></li> */}
                        {/* <li ><Link name="/medicalInfo/edit" className={location.pathname == '/account' ? 'current' :''} to="/medicalInfo/edit" onClick={linkClickHandler} >
                            <Translation>
                                {
                                    t => <>{t('Edit medical Info')}</>
                                }
                            </Translation>
                        </Link></li> */}

                        {/* <li ><Link name="/policies" className={location.pathname == '/policies' ? 'current' :''} to="/policies" onClick={linkClickHandler}>CLINIC POLICIES</Link></li>

                        <li><Link name="/faq" className={location.pathname == '/faq' ? 'current' :''} to="/faq" onClick={linkClickHandler}>FAQ</Link></li>

                        <li><Link name="/bookappointment" className={ location.pathname == '/bookappointment' ? 'current' :''} to="/bookappointment" onClick={linkClickHandler}>BOOK APPOINTMENT</Link></li> */}

                        <li><a href="#" 
                        // className={ location.pathname == '/login' ? 'current' :''} to="/login" 
                        onClick={()=>{props.logoutAction()}}>
                            <Translation>
                                {
                                    t => <>{t('LOGOUT')}</>
                                }
                            </Translation>
                        </a></li>

                        {/* <li><Link name="/register" className={ location.pathname == '/register' ? 'current' :''} to="/register" onClick={linkClickHandler}>REGISTER</Link></li> */}
                        <li><button className="btn btn-uni" style={{float: "right"}} onClick={()=>{
                            switchLang()
                            props.toggleLang()
                        }}>{ props.isEn ? "中文" : "EN"}</button></li>
                    </section>
                    
                    


                </ul>
            </nav>
            <AlertBox/>
        </Fragment>
    )
}

const mapDispatchToProps = {
    logoutAction,
    toggleLang
}

const mapStateToProps = state => {
    
    return {
        isEn: state.lang.lang == "en" || typeof state.lang.lang == "undefined",
        user: state.auth.user,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBarLoggedIn);