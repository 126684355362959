import React from 'react';

const NotFound = () => {

    return(
        <div id="not-found-container">
            <h1> 404 Not Found</h1>
            <p> the page you requested is not found</p>
        </div>
    )
}

export default NotFound;