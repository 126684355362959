

import {
    MEDICAL_RECORD_DOWNLOAD_TOKEN_INVALID,
    MEDICAL_RECORD_DOWNLOAD_TOKEN_VALID,
    MEDICAL_RECORD_GET_FILE_LIST_SUCCESS
} from './type';

import axios from 'axios';
import Config from '../config';


const config = {
    headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store, must-revalidate"
    }
};
/*\
*@ Loaduser
*/

export const loadMedicalRecordsWithToken = (params) => async dispatch => {

    if(!params || !params.token) return dispatch(tokenInvalid());

    try {
        const response = await axios.get(`${Config.service}/services2/medical/record/${params.token}`);
        if(response.data && response.data.success) {
            // validated
            dispatch(tokenValidated())
            return;
        }
        
        alert(response.data.message);

        dispatch(tokenInvalid());

    }catch(err) {
        alert(err.message);
        dispatch(tokenInvalid());

    }
}

export const getMedicalRecordsWithDob = (data) => async dispatch => {

    try {
        const {dobYear, dobMonth, dobDay, token} = data;
        console.log(data);

        const response = await axios.get(`${Config.service}/services2/verify/medical/record?year=${dobYear}&month=${dobMonth}&day=${dobDay}&token=${token}`);

        console.log(response.data);
        if(response.data.success){
            console.log(response.data.payload);
            dispatch(getFileList(response.data.payload));
            return;
        }

        alert(response.data.message);

    } catch(err) {

        console.log(err);
        alert(err.message);
        
    }
}

const getFileList = (fileList) => async dispatch => {
    dispatch({
        type:MEDICAL_RECORD_GET_FILE_LIST_SUCCESS,
        payload:fileList
    })
}

const tokenValidated = () => dispatch => {
    dispatch({
        type:MEDICAL_RECORD_DOWNLOAD_TOKEN_VALID
    })
}

const tokenInvalid = () => dispatch => {
    dispatch({
        type: MEDICAL_RECORD_DOWNLOAD_TOKEN_INVALID
    })
}