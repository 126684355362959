import React from 'react'

class OutlineButton extends React.Component {
    render() {
        var className = this.props.id + " empty-button";
        if (this.props.styles) {
            this.props.styles.forEach (function (style) {
                className += " " + style;
            });
        }

        return (
            <button className={className} onClick={this.props.action}><div className={"empty-button-font"}>{this.props.children}</div></button>
        )
    }
}

export default OutlineButton;
