// alert 

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';



// AUTH
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_LOADED = 'USER_LOADED';
export const USER_UPDATED = 'USER_UPDATED';
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS='LOGIN_SUCCESS';
export const RESET_PASSWORD='RESET_PASSWORD';
export const UPDATE_PASSWORD='UPDATE_PASSWORD';
export const UPDATE_MESSAGE='UPDATE_MESSAGE';
export const USER_FAILED_TO_CREATE_ACCOUNT = 'USER_FAILED_TO_CREATE_ACCOUNT';
export const USER_FAMILY_DOC_SET = 'USER_FAMILY_DOC_SET'

/* defining action types */
export const LOADER_LOADING = 'LOADER_LOADING';
export const LOADER_LOADED = 'LOADER_LOADED';
export const SET_LANG = 'SET_LANG';

export const SET_SICK_NOTE = 'SET_SICK_NOTE';

export const SICK_NOTE_LOADED = 'SICK_NOTE_LOADED';
export const MESSAGE_LOADED = 'MESSAGE_LOADED';
export const MESSAGE_CHANGE = 'MESSAGE_CHANGE';
export const MESSAGE_REPLIED = 'MESSAGE_REPLIED';
