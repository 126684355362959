import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { validateFileUploader } from '../../_helpers';
// import { alertAction } from '../_actions';

class FileUploader extends Component {
    constructor(props) {
        super(props);

        // init
        this.state = {
            fileName: props.placeHolder || 'Choose file'
        };
    }


    componentDidUpdate(prevProps,prevState){
        if(prevProps.fileName!==this.props.fileName){
            this.setState({fileName:this.props.fileName})
        }
    }


    handleChange = (event) => {
        const { dispatch, onChange, accept,setFileName,name, multiple } = this.props;
        let files = event.target.files;       
        // console.log('onchange',files)
        if (validateFileUploader(files, accept,3)) {
            let fileName = files.length === 1 ? files[0].name : `${files.length} files`;

            if(!multiple){
                fileName = files.length===0 ? 'Choose file' : fileName;
            }

            setFileName(fileName,name);
            
            const hasHEICFile = Array.from(files).some(file => file.name.toLowerCase().endsWith('.heic'));

            if (hasHEICFile) {
                alert("Please upload another type of image such as JPEG, JPG or PNG.");
                event.target.value = ''; // reset the file input
                setFileName('Choose file', name);
            } else {
                setFileName(fileName, name);
                onChange(files);
            }
            
        } else {
            let errorMsg = 'Only allow uploading following file type: ';

            let maxFilesError = ''

            if (accept.indexOf('application/pdf') > -1) {
                errorMsg += 'pdf, ';
            }

            if (accept.indexOf('application/json') > -1) {
                errorMsg += 'json, ';
            }

            if (accept.indexOf('image/') > -1) {
                errorMsg += 'image, ';
            }

            if (accept.indexOf('.csv') > -1) {
                errorMsg += 'csv, ';
            }

            if(files.length > 3){
                maxFilesError = 'You can only attach maximum of 3 files.'
                alert(maxFilesError);
            }

            errorMsg = errorMsg.trim().slice(0, -1);

            // dispatch(alertAction.error(errorMsg));
            
            event.target.value = '';            // reset the file
            files = undefined;
            
            setFileName('Choose file',name);
        }

        onChange(files);
    }

    // handleClick(event){
    //     const { target = {} } = event || {};
    //     console.log(target.value);
    //     target.value=''
    // }

    render() {
        const { id, multiple, accept, disabled, className,setRef } = this.props;

        return (
            <div className={className ? `${className} custom-file`: "custom-file"}>
                <input ref={setRef} type="file" className="custom-file-input" id={id} name="file" multiple={multiple}
                    onChange={this.handleChange}  accept={accept} disabled={disabled} /> 
                <label className="custom-file-label" htmlFor={id}>{this.state.fileName}</label>
            </div>
        );
    }
}


FileUploader.propTypes = {
    dispatch: PropTypes.func,
    onChange: PropTypes.func,
    id: PropTypes.string,
    multiple: PropTypes.bool,
    accept: PropTypes.string
};


export default connect()(FileUploader);;