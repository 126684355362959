import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';


// if Auth, these route redirect to /dashboard
const PublicRoute = (props) => {

    const { component,isAuthenticated, ...rest } = props;
    const Component = component;
    return(
        <Route {...rest} render={props => (
            !isAuthenticated
                ? (
                    <Component {...props} />
                )
                : (
                    <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
                )
        )} />

    )
    
};

const mapStateToProps_public = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        // user: state.auth.user,
        // accessToken: state.auth.token
    };
}

export default connect(mapStateToProps_public)(PublicRoute);