const config = {
    service: 'https://api.test.instoremed.com',
    book_appointment:'https://appointment.test.instoremed.com',
    order_labtest:'https://test.asklabtest.com',
    captcha_key:'6LfIA8UZAAAAACK5A7BA8Qbz-4jNe5mfVWKk5H00',
    askmedication:'https://test.instore.askmedication.com',
    hasFamilyDoc : "1998",
    noFamilyDoc : "1999",
    askmedApiHost: 'https://api.test.instore.askmedication.com',
    askmedSecret:'cXpMnLhQ*7#qX1i',
    stripePublicKey:'pk_test_cvvNOUg1DVxpBKGM8TZb22GN00Fkz7b4Uz',
    immigration_url:'https://immigration.test.southvanmed.com',
    homepageUrl:'InStoreMed.com',
    clinicName:'Richmond in-Store Medical Clinic',
    service_email:'info@instoremed.com'
}
export default config;