import {
    MESSAGE_LOADED,
    MESSAGE_CHANGE,
    MESSAGE_REPLIED
} from "../actions/type2";



const initialState = {
      messageObj: null,
};

export default (state = initialState, action) => {

    const { type, payload, isReplied} = action;
    switch (type) {
        
        case MESSAGE_CHANGE:
            return {
                ...state,
                sendMessageObj: { ...action.sendMessageObj }
            };
        case MESSAGE_LOADED:
      
            return {
                ...state,
                messageObj: payload,
                isReplied: isReplied,

            }

        case MESSAGE_REPLIED:
         
            return {
                ...state,
                isReplied:isReplied
            }

        default:
            return state;

    }
}