import { 
    SET_ALERT,
    REMOVE_ALERT
 } from './type';
import { v4 as uuidv4 } from 'uuid';


export const setAlert = (msg, alertType, timeOut = 10000,className) => dispath => {
    const id = uuidv4();

    dispath({
        type: SET_ALERT,
        payload: { msg, alertType, id, className }
    })

    setTimeout(() => {
        dispath({
            type: REMOVE_ALERT,
            payload: id
        })
    }, timeOut)


}