import React from 'react';
import { useLocation } from 'react-router-dom';
import { Translation } from 'react-i18next';
import TermsPrivacyFee from './TermsPrivacyFee';

const Footer = (props) => {    
    let d = new Date();
    let year = d.getFullYear();
    const location = useLocation();

    
    if(location.pathname.includes('login') || location.pathname.includes('/medical/records')){
        return null;
    }


    return (
        <>
            <footer id="main-footer">
                <div className="container">
                <div className="footer-container">
                    <div className="footer-container-top p-3">
                    <div>
                        <h1 className="title">
                            <Translation>
                                {
                                    t => <>{t('Regular')}</>
                                }
                            </Translation>  
                        <span className="keywords
                        font-it">
                            <Translation>
                                {
                                    t => <>{t('Hours')}</>
                                }
                            </Translation> 
                        </span></h1>
                        <div>
                        <ul>
                            <li>
                            <p>
                                <Translation>
                                    {
                                        t => <>{t('Monday')}</>
                                    }
                                </Translation> - <Translation>
                                    {
                                        t => <>{t('Friday')}</>
                                    }
                                </Translation>: <Translation>
                                    {
                                        t => <>{t('in-person and telemedicine')}</>
                                    }
                                </Translation>   
                            </p>
                            </li>
                            <li>
                            <p>
                                <Translation>
                                    {
                                        t => <>{t('Saturdays')}</>
                                    }
                                </Translation>
                                &nbsp; &amp; <Translation>
                                    {
                                        t => <>{t('Sundays')}</>
                                    }
                                </Translation>  :  <Translation>
                                    {
                                        t => <>{t('Closed')}</>
                                    }
                                </Translation></p>
                            </li>
                            <li>
                            <p>B.C. <Translation>
                                    {
                                        t => <>{t('Statutory Holidays')}</>
                                    }
                                </Translation>: <Translation>
                                    {
                                        t => <>{t('Closed')}</>
                                    }
                                </Translation></p>
                            </li>

                        </ul>
                        </div>
                               
                                <small className="font-it"><Translation>
                                    {
                                        t => <>{t('Please note that exceptionally hours may change without notice.')}</>
                                    }
                                </Translation></small>
                                <br/>
                                
                                <small className="font-it"><Translation>
                                    {
                                        t => <>{t('Please always book an appointment online as walk-ins during business hours may not be able to see a doctor')}</>
                                    }
                                </Translation></small>
                                
                    </div>
                    <div>
                        <h1 className="title">
                            <span className="keywords font-it">
                                <Translation>
                                        {
                                            t => <>{t('Address')}</>
                                        }
                                </Translation>
                            </span>
                        </h1>
                        <h3>Richmond In-Store Medical Clinic</h3>
                        <p>
                            <Translation>
                                {
                                    t => <>{t('Inside Richmond Superstore, next to the pharmacy.')}</>
                                }
                            </Translation>
                        </p>
                        <p>4651 No. 3 Road, Richmond, BC V6X 2C4</p>
                        <p> 
                            <Translation>
                                {
                                    t => <>{t('Email')}</>
                                }
                            </Translation>: <i>info@inStoreMed.com</i>
                        </p>
                        <p>
                            <Translation>
                                {
                                    t => <>{t('Fax')}</>
                                }
                            </Translation>: <i>(604) 303-7611</i>
                        </p>
                    </div>
                    </div>

                    <div className="footer-container-bottom ">
                    <span>Copyright © { year }, Richmond In-Store Medical Clinic. All Rights
                    Reserved</span>
                    </div>

                    <TermsPrivacyFee />

                </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;