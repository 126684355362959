export const userConstant = {
    LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
    LOGIN_VERIFY:  'USER_LOGIN_VERIFY_MFA',
    
    LOGOUT: 'USER_LOGOUT',

    GETALL_REQUEST: 'USER_GETALL_REQUEST',
    GETALL_SUCCESS: 'USER_GETALL_SUCCESS',
    GETALL_FAILURE: 'USER_GETALL_FAILURE',

    DELETE_REQUEST: 'USER_DELETE_REQUEST',
    DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    DELETE_FAILURE: 'USER_DELETE_FAILURE',

    ROLE_DOCTOR: 'doctor',
    ROLE_PATIENT: 'patient',
    ROLE_ADMIN: 'admin',
    ROLE_CUSTOMERSERVICE: 'customerservice',

    GET: 'USER_GET',
    CHANGE: 'USER_CHANGE',
    REFRESH: 'USER_REFRESH',

    UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    UPDATE_EMAIL: 'USER_UPDATE_EMAIL',
    IS_LOGGING: 'USER_IS_LOGGING',
    IS_TRIED: 'USER_IS_TRIED',

    BACK_TO_DASHBOARD:'BACK_TO_DASHBOARD'
};
