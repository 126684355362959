import React from 'react';
import Modal from 'react-bootstrap/Modal';

import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Button from 'react-bootstrap/Button'
import config from '../../config'

const TermsModal = (props) => {

    return (
        <Modal
            show={props.show}
            onHide={e => props.footerClick(e, false)}
            size="lg"
            centered
            name="terms"
        // aria-labelledby="example-custom-modal-styling-title"
        >
            <ModalHeader className="centered display-block">
                <ModalTitle id="terms-title">
                    {config.homepageUrl}<br />
                    TERMS OF USE
              </ModalTitle>
            </ModalHeader>
            <ModalBody id="terms-body">
                <p className="centered">Please read this document completely.
                Continuing onto {config.homepageUrl} means that you agree with these Terms of Use.</p>

                <p>Last updated: March 4, 2022 - Version 2.0 <br/><br/>
                {config.clinicName} ({config.clinicName}, "we", "us", or "our") owns and operates the website www.{config.homepageUrl}, including any subdomains thereof (the "Website"). These Terms of Use (the "Terms") and our <button className="button-link important-info-link font-1 m-0" name="privacy-policy" onClick={e=>props.footerClick(e,true,true,'showTerms')}>Privacy Policy</button> and <button className="button-link important-info-link font-1 m-0" name="fee-schedule" onClick={e=>props.footerClick(e,true,true,"showTerms")}>Fee Schedule</button> govern your use of the Website and the content available therein. Please carefully read these Terms and the Privacy Policy, as they contain important information about your rights and responsibilities. By accessing this Website you acknowledge that you have read, understood and agree with the Terms and the Privacy Policy. If you do not wish to be bound by these Terms, please stop using this website immediately.</p>

                <p>By way of the Website, we offer to certain users ("User", or "you") access to certain services provided by us by way of a secure online platform (the "Portal"). These Terms apply additionally to a User's use of the Portal.</p>
                <p>IF YOU DO NOT AGREE WITH ANY PART OF THESE TERMS OR THE Privacy Policy YOU MUST IMMEDIATELY DISCONTINUE ANY USE OF THE WEBSITE AND/OR PORTAL AND ANY SERVICES OFFERED OR PROVIDED BY WAY OF SAME.</p>
                <p>DO NOT USE THE WEBSITE AND/OR PORTAL TO COMMUNICATE WITH US OR FOR TREATMENT OF MEDICAL EMERGENCIES. IF YOU ARE EXPERIENCING AN URGENT MEDICAL ISSUE OR A RESPONSE FROM US IS NEEDED QUICKLY REGARDING YOUR HEALTH CARE, YOU MUST INSTEAD GO TO A NEARBY URGENT CARE CENTRE OR EMERGENCY DEPARTMENT, OR CALL 9-11 IMMEDIATELY.</p>
                <hr />
                <h3>ACCEPTANCE</h3>
                <p>These Terms comprise an electronic contract that establishes the legally binding terms you must accept to use the Website. The Terms include our Privacy Policy.</p>
                <p>By accessing or using the Website you accept and agree to the terms, conditions and notices contained or referenced herein and consent to have the terms and all notices provided to you in electronic form. The Terms may be modified by us from time to time. Such modifications will become effective upon posting on the Website. We shall notify you of changes to the Terms through notices on the Website, by email, or by both. To withdraw your consent, you must cease using the Website.</p>
                <hr/>
                <h3>USE OF THE WEBSITE AND PORTAL</h3>
                You are not permitted to use the Website or Portal:
                
                <ol type="a">
                    <li >in any unlawful, fraudulent, or commercial manner, or any other manner prohibited by these Terms;</li>
                    <li >to upload, transmit, or distribute to or through the Website or Portal any computer viruses, worms, or any software intended to damage or alter a computer system or data;</li>
                    <li >to send through the Website and/or Portal unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise;</li>
                    <li >to harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses, without their consent;</li>
                    <li >to interfere with, disrupt, or create an undue burden on servers or networks connected to the Website and/or Portal, or violate the regulations, policies or procedures of such networks;</li>
                    <li >to attempt to gain unauthorized access to the Website (or to other computer systems or networks connected to or used together with the Website, including but not limited to, the Portal), whether through password mining or any other means;</li>
                    <li >to harass or interfere with any other Website or Portal user's use and enjoyment of the Website or Portal;</li>
                    <li >to use software or automated agents or scripts to produce multiple accounts on the Portal, or to generate automated searches, requests, or queries to (or to strip, scrape, or mine data from) the Website or Portal;</li>
                    <li >to tamper with, modify, copy without express permission, amend, make derivative or reverse engineer any part of the Website or Portal; or</li>
                    <li >to licence, sell, rent or lease any part of the Website or Portal.</li>
                </ol>
                <hr/>

                <h3>THE PORTAL</h3>

                <p>The Portal provides a secure online platform which enables you to receive patient care services (the "Services") from one of our physicians licensed to practice medicine in the Province of British Columbia. Access to and use of the Portal requires that Users create a patient user account (the "User Account"). In order to create the User Account, you must provide your certain personal information and identification , and agree to these Terms, our Privacy Policy and our Fee Schedule</p>

                <p>You agree to treat your interactions with the Portal confidentially, and to not disclose to any other person or entity your user name, password, or any other piece of information chosen by you or provided to you as part of our security procedures. You agree to exercise caution when accessing your User Account so that others are not able to view or record your password. Your User Account is personal to you and you agree to not provide any other person with access to the Portal or any part thereof, nor to any information contained therein, such as your user name, password, or other security information.</p>

                <p>You agree that you will not use the Portal for improper, unethical or fraudulent purposes. It is a condition of your use of the Portal that all the information you submit is correct, current, and complete.</p>

                <p>We reserve the right, at any time, to suspend, disable or terminate your User Account, any username, password, or other identifier, whether chosen by you or provided by us, in our sole discretion for any or no reason, including any violation of any provision of these Terms.</p>

                <p>You may stop using the Portal or deactivate your User Account at any time. Should you discontinue your use of the Portal, or if your User Account is discontinued or terminated for any reason, your User Account will be deactivated. We will work with you to fulfill any personal information access requests.</p>

                <p>As long as you maintain an active User Account, you consent to, and may not "opt out" of receiving Service-related communications from us.</p>
                
                <p><strong>Terms Specific to the Portal</strong></p>
                
                <p>By the User checking the <strong>"I Have Read and Accepted the Terms of Use and the Privacy Policy"</strong> box, a binding agreement is executed between the User and {config.clinicName}. As such, we strongly recommend that the User reads these Terms carefully, and seeks (or waives their right to) independent legal counsel as required.</p>

                <p>If the User breaches any provision of the Terms, the User's right to access the Portal and the performance of the Services, if applicable, shall cease immediately.</p>
                <p>By agreeing that the User has read and accepted the terms and conditions contained herein and in the Privacy Policy and Fee Schedule, and by completing the User Registration Form, the User represents and warrants that:</p>

                <ol>
                    <li>the User is at least 19 years of age or a legally recognized person authorized to represent the person who is submitting a User registration, and to sign the User registration form on the User's behalf and bind the User; and</li>
                    <li>the information furnished by the User in the User Registration Form is accurate, true and complete.</li>
                </ol>

                <p>The Portal (and any portion thereof), the Services offered therethrough, may be modified by us from time to time at our sole discretion, such modifications to be effective upon posting. We will notify users of any such changes and to any changes to the Terms, including the Privacy Policy and Fee Schedule, through notices on the Portal or by email, or both. Users are advised to review these Terms regularly. Continued use of the Portal and/or Service(s) will signify acceptance of any modifications by the User.</p>

                <p><strong>Scope of the Portal</strong></p>

                <p>The Portal is offered only as an assistive step prior to the performance of certain Services by {config.clinicName} physicians and/or staff. The Portal exists solely for the purposes of: (a) assisting in the collection and provision of consent; (b) facilitating communications necessary for the performance of such Services; and (c) serving as a medium through which payments for Services can be made (the "Purposes"). The Portal does not itself offer or provide medical advice or diagnostic services.</p>

                <hr/>

                <h3>EMAIL COMMUNICATIONS</h3>

                <p>{config.clinicName} may, from time to time, communicate with you via email with respect to our Services, and in so doing, may use some or all of the information you provide us with when registering for a User Account. The contents of those emails may include, but are not limited to, confirmations of appointments, laboratory and/or radiology requisitions, and brief notes regarding your medical condition. {config.clinicName} will take all reasonable steps to ensure that the transmission of any such information to you is done as securely as possible, and will only transmit as much information, including personally identifiable information, as is necessary for the purposes for which any such communications are made. You agree to allow {config.clinicName} to transmit any such information via email. If you do not agree to such transmission, please contact {config.clinicName}, and alternative arrangements may be made to allow you to retrieve this information.</p>
                <hr/>
                <h3>PAYMENT OF FEES</h3>
                <p>By using the Portal, you agree to provide payment to the {config.clinicName}, in accordance with the Fee Schedule. By using the Portal and the Services made available therethrough, you accept and are bound by the Fee Schedule.</p>

                <p>All prices posted on the Fee Schedule are subject to change without notice. The price charged for a service will be the price advertised on the Fee Schedule at the time the Services are sought. We strive to display accurate fee information;</p>

                <p>however, we may, on occasion, make inadvertent typographical errors, inaccuracies or omissions related to our fees. We reserve the right to correct any errors, inaccuracies or omissions at any time and to cancel any requests for Services.</p>

                <p>All prices posted on the Fee Schedule are subject to change without notice. The price charged for a Service will be the price advertised on the Fee Schedule at the time the Services are sought. </p>

                <p><strong>Terms of payment are within our sole discretion and payment must be received by us in advance of our acceptance of a request for our Service. </strong></p>
                <p><strong>We utilize a third party payment processor, Stripe, to receive payment in accordance with our Fee Schedule. </strong></p>
                <p><strong>You confirm that, at the time of payment, the following will be accurate:</strong></p>

                <ol type="i">
                    <li className="bold">the credit card information you supply to us is true, correct and complete;</li>
                    <li className="bold">you are duly authorized to use such credit card for the purchase;</li>
                    <li className="bold">charges incurred by you will be honoured by your credit card company; and </li>
                    <li className="bold">you will pay charges incurred by you in accordance with the Fee Schedule, plus all applicable taxes, if any.</li>
                </ol>
                <hr/>

                <h3>OTHER PROHIBITED SECURITY VIOLATIONS</h3>
                <p>You must not attempt to circumvent or violate the security of the Website or Portal including, without limitation</p>

                <ol type="i">
                    <li>accessing content and data that is not intended for you;</li>
                    <li>attempting to breach or breaching the security or authentication measures without our prior authorization;</li>
                    <li>restricting, disrupting, or disabling service to users, hosts, servers or networks;</li>
                    <li>(iv)illicitly reproducing TCP/IP packet header;</li>
                    <li>disrupting network services and otherwise disrupting the Website and/or Portal owner's ability to monitor the Website or Portal;</li>
                    <li>using any robot, spider, or other automatic device, process, or means to access the Website and/or Portal for any purpose, including monitoring or copying any of the material on the Website or Portal;</li>
                    <li>introducing any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful;</li>
                    <li>attacking the Website or Portal via a denial-of-service attack, distributed denial-of-service attack, flooding, mail bombing or other technologically harmful event; or </li>
                    <li>otherwise attempting to interfere with the proper working of the Website or Portal.</li>
                </ol>

                <hr/>

                <h3>INTELLECTUAL PROPERTY AND USE OF MARKS</h3>

                <p>{config.clinicName} either owns the proprietary rights, including copyright, to, or has acquired the necessary permissions to use, to the Website and Portal, and in all content, trademarks, trade names, service marks, documents and other materials, including all text, HTML (hypertext markup language) code, multimedia clips, images, graphics, icons, JavaScript code, and other intellectual property and proprietary materials related thereto (the "Intellectual Property"). Unauthorized use by you of the Information may violate copyright, trademark, and other laws.</p>

                <p>{config.homepageUrl} and related names displayed on the Website and/or Portal are the trademarks, service marks or registered trademarks of {config.clinicName}. Except as provided for under these Terms, any reproduction of any of these marks without our express written consent is strictly prohibited. Except as expressly granted, nothing contained in these Terms shall be construed as conferring any license or right to the Intellectual Property or other proprietary rights of {config.clinicName} to you.</p>

                <p>We grant you a nonexclusive, non-transferable, and revocable to use the Website and/or Portal for your, <strong>personal home use only</strong>, provided that you: (i) abide by these Terms; and, (ii) do not copy, modify, transmit, adapt, create derivative works from, make use of, decompile, decode, disassemble, reverse engineer, reverse assemble, attempt to discover any source code or architectural framework, or reproduce in any way the Intellectual Property. You agree to not remove, obscure or otherwise alter any proprietary notices appearing on any content, including copyright, trademark and other intellectual property notices.</p>

                <p>Subject to the limited licenses granted in these terms, no licence is granted to you or any other party for the use of our Intellectual Property.</p>

                <p>Any third-party trademarks, service marks or other intellectual property displayed on through the Website or Portal are used with the authorization of the owner of the intellectual property, subject to their guidelines for use. We cannot authorize you to use, reproduce or modify any third party intellectual property used on the Website or Portal, and are not responsible for any loss or damage you may suffer or incur in connection with your use of any third party intellectual property for your own purpose.</p>
                <hr/>

                <h3>THIRD-PARTY LINKS AND AFFILIATES</h3>
                <p>We may provide links through the Website and/or Portal to the websites of third parties. These websites are owned and operated by third parties over whom we do not have control. We have not reviewed all of the sites linked through the Website and/or Portal and accept no responsibility for the contents or use of third-party websites. The inclusion of any link does not imply endorsement by us of the website. Use of any such linked websites is at your own risk. Any links to third-party websites are provided for your interest and convenience only. We are not responsible or liable for any loss or damage you may suffer or incur in connection with your use of any third-party websites or for any acts, omissions, errors or defaults of any third party in connection with their websites.</p>

                <hr/>
                <h3>DISCLAIMERS</h3>
                <p>The South Vancouver Medical Clinic, to the fullest extent permitted by law, disclaims any and all warranties, either expressed or implied, statutory or otherwise, makes no representations or warranties of any kind including, without limitation any representations or warranties about the accuracy, reliability, completeness, currency or timeliness of the Website or Portal, or the Services which may be provided by way of same, or any results or outcomes that may be obtained from the use of the Portal or the Services. You acknowledge and agree that the Website, Portal and the Services provided therethrough are provided to you on an "as is" and "as available" basis.</p>
                <p>If you use any of the Website, Portal and/or Services, you do so at your own risk. We expressly disclaim any warranties of any kind, whether express or implied, including, without limitation, implied warranties of merchant ability, fitness for a particular purpose, title and non-infringement.</p>
                <p>If you access or transmit any content through the use of the Website or Portal, you do so at your own discretion and your sole risk. You are solely responsible for any loss or damage to you in connection with such actions. We are not responsible for any incorrect or inaccurate content in connection with the Website or Portal. We are not responsible for the conduct, whether online or offline, of any user of the Website or Portal. We assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, user communications.</p>

                <p>For greater certainty, we do not warrant that:</p>
                <ol type="a">
                    <li>the Website or Portal or any Service(s) provided therethrough will meet your requirements;</li>
                    <li>access to the Website or Portal will be uninterrupted, timely, secure, or error-free;</li>
                    <li>the quality or reliability of the Website or Portal will meet your expectations;</li>
                    <li>any information you provide or we collect will not be disclosed to third parties; and</li>
                    <li>third parties will not use your confidential information in an unauthorized manner.</li>
                </ol>
                <hr/>
                <h3>LIMITATIONS OF LIABILITY</h3>
                <p>You acknowledge and agree that {config.clinicName} shall not be responsible or liable for any harm, loss, damages, injury, claims or liabilities whatsoever (including without limitation, direct, indirect, special, punitive or consequential damages, or damages for loss of information), whether {config.clinicName} has been advised of the possibility of such damage or loss, arising from or in connection with:</p>
                <ol type="a">
                    <li>any use of or reliance on, or inability to use, the Website, Portal or the Services;</li>
                    <li>use of or reliance on any information stored, provided or otherwise communicated between you and {config.clinicName};</li>
                    <li>actions or inactions of other users or any other third parties for any reason;</li>
                    <li>any other matter arising from, relating to or connected with the Website, Portal, or Services provided therethrough, or these Terms.</li>
                </ol>
                <p>{config.clinicName} assumes no liability or responsibility for any:</p>
                <ol type="a">
                    <li>personal injury, property, bodily, or material damage of any nature whatsoever, resulting from your inability to access or access to and use of the Website, Portal or the Services; </li>
                    <li>any unauthorized access to or use of our servers and/or any and all personal information stored on our servers</li>
                    <li>any interruption or cessation of transmission to or from the Website or Portal;</li>
                    <li>any bugs, viruses, trojan horses, denial-of-service attack, flooding, logic bombs or other technologically harmful material or event which may be transmitted to or through the Website or Portal; or </li>
                    <li>any errors or omissions in any information, including without limitation the content of the Website or Portal.</li>
                </ol>

                <p>We will not be liable for any failure or delay in performing under these Terms where such failure or delay is due to causes beyond our reasonable control, including natural catastrophes, governmental acts or omissions, laws or regulations, terrorism, labor strikes or difficulties, communication system breakdowns, hardware or software failures, transportation stoppages or slowdowns or the inability to procure supplies or materials.</p>

                <p>You acknowledge and agree that above <span className="bold">DISCLAIMERS</span> and these <span className="bold">LIMITATIONS OF LIABILITY</span> are an agreed upon allocation of risk between you and {config.clinicName}. You acknowledge and agree that if you did not agree to these limitations of liability you would not be permitted to access the Website, Portal or Services. You acknowledge and agree that such provisions are reasonable and fair.</p>
                <hr/>

                <h3>INDEMNIFICATION</h3>
                <p>You agree to defend, indemnify and hold us, and our subsidiaries, parents, affiliates, and each of our and their directors, officers, managers, partners, agents, other representatives, employees and customers (each an "Indemnified Party"), harmless from any claim, demand, action, damage, loss, cost or expense, including without limitation, lawyers' fees and costs, investigation costs and settlement expenses incurred in connection with any investigation, claim, action, suit or proceeding of any kind brought against any Indemnified Party arising out of your use of the Website, Portal and/or Services, any alleged or actual infringement of the intellectual property rights of any party, any injury or damage to property or person, any act by you in connection with any user of the Website, Portal and/or Services or any other third party, or alleging facts or circumstances that could constitute a breach by you of any provision of these Terms and/or any of the representations and warranties set forth above.</p>
                <hr/>
                <h3>TERMINATION</h3>
                <p>You acknowledge and agree that we, in our sole discretion, may terminate your access to the Website and/or Portal for any reason, including, without limitation, your breach of these Terms. You understand and agree that we are not required, and may be prohibited, from disclosing to you the reason for termination of your access to the Website and/or Portal. You acknowledge and agree that any termination of your access to the Website and/or Portal may be effected without prior notice, and acknowledge and agree that we may immediately deactivate or delete your account and bar any further access to the Website and/or Portal. Further, you acknowledge and agree that we will not be liable to you or any third party for any costs or damages of any kind for or resulting from any termination of your access to the Website and/or Portal. Upon termination, your information may be destroyed or kept as necessary.</p>
                <hr/>
                <h3>DISPUTE RESOLUTION</h3>
                <p>In the event a dispute arises out of or in connection with these Terms, the parties shall attempt to resolve the dispute through friendly consultation.</p>
                <p>If the dispute is not resolved within a reasonable period then any or all outstanding issues shall be referred to mediation on notice by one party to the other, with the assistance of a neutral mediator jointly selected by the parties. If the dispute cannot be settled within thirty (30) days after the mediator has been appointed, or within such other period as agreed to by the parties in writing, either party may refer the dispute to arbitration under the International Commercial Arbitration Rules of Procedure of the British Columbia International Commercial Arbitration Centre (the "BCICAC"). The appointing authority shall be the BCICAC and the case shall be administered by the BCICAC in accordance with its Rules.</p>
                <hr/>
                <h3>GENERAL</h3>
                <p>These Terms shall be governed by, and construed under, the laws of British Columbia, and the laws of Canada applicable therein. You irrevocably submit to the exclusive jurisdiction of the courts located in the Province of British Columbia. The {config.clinicName} makes no representations that the Portal or the Services are available for use in other locations.</p>

                <p>Notwithstanding any other agreement or understanding between you and {config.clinicName}, these Terms, the Privacy Policy and the Fee Schedule, as updated from time to time, shall, in combination, constitute the sole and entire agreement between you and {config.clinicName}with respect to the Website, Portal, and Services offered therethrough, and supersedes and invalidates all other commitments, representations, warranties, conditions and understanding relating to the subject matter hereof, whether oral or written.</p>

                <p>If any provision of these Terms is held by a court of competent jurisdiction to be unenforceable, the unenforceable portion shall be construed in accordance with applicable law as nearly as possible to reflect its original intentions and the remainder of the provisions shall remain in full force and effect.</p>
                <p>We shall be excused from performance under these Terms if we are prevented, forbidden or delayed from performing, or omits to perform, any act or requirement under these Terms by reason of, without limitation: (a) any provision of any present or future law or regulation; (b) any act or omission of a third party; (c) any act of God, emergency condition (including pandemics or epidemics), or war; (d) acts of civil authorities; (e) acts of military authorities; (f) riots; (g) embargoes; (h) acts of nature or natural disasters; (i) computer or telecommunications or other technological failure; or (j) any other circumstance beyond the control of {config.clinicName}.</p>
                <p>The terms, provisions, covenants, and conditions contained in these Terms which, by their terms, require their performance after the expiration or other termination of these Terms will be and remain enforceable notwithstanding the expiration or other termination of these Terms for any reason whatsoever.</p>
                <p>No failure or delay by either party in exercising any right under these Terms shall constitute a waiver of that right.</p>
                <hr/>

                <h3>MODIFICATIONS, AMENDMENTS and ASSIGNMENT</h3>
                <p>{config.clinicName} may, in its sole discretion, modify or revise these Terms, its Privacy Policy, or its Fee Schedule from time to time without notice and you agree to be bound by such modifications or revisions. Your continued use of the Website, Portal, or the Services provided therethrough constitutes your acceptance of such modifications. You should visit these pages often to review the Terms, Privacy Policy, and Fee Schedule. Nothing in these Terms shall be deemed to confer any third party rights or benefits.</p>

                <p>We may change, withdraw, or terminate the information, material, or any service or function provided on or through the Website or the Portal, at any time at our sole discretion without notice. We are not liable if, for any reason, all or any part of the Website or Portal is restricted to Users or unavailable at any time or for any period.</p>
                <hr/>

                <h3>CONTACT</h3>
                <p>If you have any questions or concerns about these Terms, the Privacy Policy, or the Fee Schedule, you may contact us at info@{config.homepageUrl}</p>

            </ModalBody>
            <ModalFooter>
                <Button name="terms" variant="secondary" onClick={e => props.footerClick(e, false)}>Close</Button>
                {/* <Button variant="primary" onClick={props.confirm}>{props.confirmText}</Button> */}
            </ModalFooter>
        </Modal>

    )

}

export default TermsModal;