import { alertAction } from '../actions';
import { appConstant } from '../Constants';
import { getErrorMsg } from './';

const errorHandling = (errorLog, errorObj, dispatch) => {
    let errMsg = 'Error';
    const errMsgs = [];

    if (typeof errorObj === 'string') {
        // errorObj is string
        errMsg = `${errMsg} - ${errorObj}`;
    } else {
        // no api error
        if (errorObj.message === 'Network Error') {
            dispatch({
                type: appConstant.API_NOT_AVAILABLE
            });

            return;
        }

        if (errorObj.response && errorObj.response.data) {
            const data = errorObj.response.data;
            // system error
            if (data.sysError) {
                errorLog = `${errorLog}: ${data.sysError}`;
                errMsg = `${errMsg} - ${getErrorMsg(data.sysError)}`;
            } else if (data.data && data.data.message) {
                errMsg = `${errMsg} - ${data.data.message}`;
            } else if (data.message) {
                errMsg = `${errMsg} - ${data.message}`;
            }

            // custom errors
            if (data.errors) {
                data.errors.map(obj => errMsgs.push(obj.message));
            }
        } else if (errorObj.message) {
            errMsg = `${errMsg} - ${errorObj.message}`;
        }
    }

    dispatch(alertAction.error(errMsg, errMsgs));
};


export {
    errorHandling
};
