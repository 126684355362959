import React,{lazy,Suspense} from 'react';
import {connect} from 'react-redux';
import { scroller } from 'react-scroll';
import { Translation } from 'react-i18next';
import { Helmet } from 'react-helmet';
// import i18next from 'i18next';

// import ReCAPTCHA from 'react-google-recaptcha';
const ReCAPTCHA = lazy(()=> import ('react-google-recaptcha'));

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button'
import { Redirect, Link } from "react-router-dom";
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import TermsPrivacyFee from '../components/layout/TermsPrivacyFee';
import PropagateLoader from './layout/PropagateLoader';

// components
import Alert from './layout/Alert';

//actions
import {loginAction} from '../actions/auth'
import {setAlert} from '../actions/alert'
import { setLang } from '../actions/lang';
import { getLangCode } from '../services/lang';

// assets



import config from '../config';

class Login extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            email:'',
            password:'',
            recaptcha:'',
            isLoading: false

        }
    }

    componentDidMount(){
        this._scrollToTop();

        this.captcha && this.captcha.reset();
    }
    componentWillMount() {
        this.captcha && this.captcha.reset();
        // this.props.setLang(this.props.isEn ? "en": "cn")
    }

    _scrollToTop(){
        scroller.scrollTo("login-container", {
            duration: 800,
            smooth: true,
            offset: -200
        });

    }

    onChange(e) {
        this.setState({
            [e.target.name] : e.target.value
        })

    }

    handleReCaptcha(val){
        this.setState({
            recaptcha:val
        })
    }

    _validateInput(){

        let isInputValid = true;
        
        if(this.state.email.length==0){
            this.props.setAlert('Email cannot be empty.','danger');
            isInputValid = false;
        }

        if(this.state.password.length==0){
            this.props.setAlert('Password cannot be empty.','danger')
            isInputValid = false;
        }

        return isInputValid;
    }

   async emailSubmit(e) {
        e.preventDefault();

        if(!this._validateInput()) return;
        
        this.setState({
            isLoading:true
        })
        

        try{

            let username = this.state.email.split(" ").join("").toLowerCase();
            let res =  await this.props.loginAction(username, this.state.password,this.state.recaptcha);

            if(!res.success){
                this.captcha.reset();
                this.setState({
                    isLoading:false,
                    recaptcha:''
                })
              let alertMsg = res.extras.message || 'Login Failed';
                this.props.setAlert(alertMsg,"danger")
            }

        }catch(e){
            if(this.captcha) this.captcha.reset();
            // show alert
            this.setState({
                isLoading:false,
                recaptcha:''
            })

            this.props.setAlert('Login Failed',"danger")
            
        }
    }
    renderTitle(){

        return (
            <>
                <div className="title-section">
                <div className="">
                    <h1 className="welcome-title keywords">
                        <Translation>
                        {
                            t => <>{t('Welcome to the Richmond In-Store Medical Clinic')}</>
                        }
                        </Translation>
                    </h1>

                    <h2 className="content-title mb-2">
                        <Translation>
                        {
                            t => <>{t('Home of ')}</>
                        }
                        </Translation>
                        {/* <a className="small keywords under-line color-red" href="https://asklabtest.com">AskLabTest.com,</a> */}
                        <a className="small keywords under-line color-red" href="https://instore.AskMedication.com">Instore.AskMedication.com</a>
                        {/* <Translation>
                        {
                            t => <>{t(' and ')}</>
                        }
                        </Translation> */}

                        {/* <a className="small keywords under-line color-red" href="https://ICBCclinic.com">ICBCclinic.com</a> */}
                    </h2>

                    {/* <h2 className="mt-2">
                        <Translation>
                        {
                            t => <>{t('Our Services, ')}</>
                        }
                        </Translation>
                    <span className="font-it keywords text-danger small">
                        <Translation>
                        {
                            t => <>{t('free with a valid BC Services Card (CareCard)')}</>
                        }
                        </Translation>
                    </span></h2>
                    <div className="bottom-line"></div> */}
                </div>
                </div>
            </>
        )
    }

    renderMultipleBox(){

        return (
            <>
                <Container>
                    <Row>
                        <Col sm={12} md={12} lg={4}>
                                 <Jumbotron style={{padding: "2em"}}>
                                    <h2>
                                        <Translation>
                                        {
                                            t => <>{t('Laboratory Testing')}</>
                                        }
                                        </Translation>
                                    </h2>
                                    <p style={{minHeight: "120px"}}>
                                        <Translation>
                                        {
                                            t => <>{t('lab_sentence_1')}</>
                                        }
                                        </Translation>
                                        <span className={"text-danger"}>
                                            <Translation>
                                            {
                                                t => <>{t('lab_sentence_2')}</>
                                            }
                                            </Translation>
                                        </span>
                                    </p>
                                </Jumbotron>
                            </Col>
                            <Col sm={12} md={12} lg={4}>
                                <Jumbotron style={{padding: "2em"}}>
                                    <h2>
                                        <Translation>
                                        {
                                            t => <>{t('Medication Refill')}</>
                                        }
                                        </Translation>
                                    </h2>
                                    <p style={{minHeight: "120px"}}>
                                        <Translation>
                                        {
                                            t => <>{t('Refill up to 5 medications per day using our Instore.AskMedication.com website.')}</>
                                        }
                                        </Translation>  
                                        <span className={"text-danger"}>
                                            <Translation>
                                            {
                                                t => <>{t("The most convenient way to refill your routine prescriptions!")}</>
                                            }
                                            </Translation>  
                                        </span>
                                    </p>
                                </Jumbotron>
                            </Col>
                            <Col sm={12} md={12} lg={4}>
                                <Jumbotron style={{padding: "2em"}}>
                                    <h2>
                                        <Translation>
                                        {
                                            t => <>{t('Book Appointment')}</>
                                        }
                                        </Translation>
                                    </h2>
                                    <p style={{minHeight: "120px"}}>
                                        <Translation>
                                        {
                                            t => <>{t('Book an in-person, phone, driver physical or ICBC appointment at the South Vancouver Medical Clinic.')}</>
                                        }
                                        </Translation>
                                    </p>
                                </Jumbotron>
                            </Col>
                        </Row>
                    </Container>
            </>
        )
    }

    render(){

        if(this.props.isAuthenticated){
            return(
            <Redirect to='/dashboard'></Redirect>
            )
        }


        let buttonDisabled = !(this.state.email && this.state.password && this.state.recaptcha) || this.state.isLoading;
        
        return (
            <>  
                <Helmet>
                    <title>Richmond In-Store Medical Clinic | Login</title>
                    <link rel="canonical" href="https://instoremed.com/login" />
                    
                    <meta name="description" content="The Richmond In-Store Medical Clinic login page "/>
                </Helmet>

                <div id="login-container">
                <div className="login-static-img">
                    {/* <span className="helper"></span>
                    <img src={loginImage} alt=""></img> */}
                </div>

                <div className="login-section">
                {this.renderTitle()}
                {/* {this.renderMultipleBox()} */}
                    <div className="login-panel">
                        <Alert />
                        {/* <div className="login-icon"><i className="fas fa-user"></i></div> */}
                        
                        {/* <i className="fa fa-user fa-3x"></i> */}
                        <i className="fa-solid fa-user-lock fa-3x"></i>

                        <form onSubmit={this.emailSubmit.bind(this)}>
                        <Translation> 
                            {
                                t => <input
                                    className="fill-content"
                                    type="text"
                                    name="email"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.email} 
                                    placeholder={t("Email")}
                                    autoComplete="username" 
                                />
                            }
                        </Translation>
                        <br/>
                        <Translation> 
                            {
                                t => <input
                                className="fill-content"
                                type="password"
                                name="password"
                                onChange={this.onChange.bind(this)}
                                value={this.state.password}
                                placeholder={t("Password")}
                                autoComplete="current-password"
                            />
                            }
                        </Translation>
                        <br/>
                        <div>
                            <Suspense fallback={<PropagateLoader isLoading={true}/>}>
                                <ReCAPTCHA
                                    ref={e => (this.captcha = e)}
                                    className="g-recaptcha"
                                    sitekey={config.captcha_key}
                                    onChange={this.handleReCaptcha.bind(this)}
                                    hl={getLangCode()}
                                />
                            </Suspense>
                        </div>
                            <br/>
                        
                        <Translation>
                            {
                                t => <Link className="color-red" to="/forgotpassword">{t('Forgot password')}</Link>
                            }
                        </Translation> 
                        <br/>
                        <span className="minor-text">
                            <Translation>
                                {
                                    t => <>{t('New to Richmond In-Store Medical Clinic?')}</>
                                }
                            </Translation> 
                        </span>
                        <Link className="color-red" to="/register">
                            <Translation>
                                {
                                    t => <>{t('Sign Up')}</>
                                }
                            </Translation> 
                        </Link>
                        <br/>
                        <br/>
                        <Button className="fill-content btn-login-page" variant="primary" disabled={buttonDisabled} type="submit">
                                {this.state.isLoading 
                                ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : <Translation>
                                    {
                                        t => <>{t('Log in')}</>
                                    }
                                </Translation>}
                        </Button>
                        
                        </form>
                        <TermsPrivacyFee />
                        </div>
                        {/* <div class="floating-btn-container">
                        <Link class="btn btn-login-page" to='/immigration'> <i class="fab fa-canadian-maple-leaf"></i> <Translation>
                                    {
                                        t => <>{t('Book Immigration Medical Exam')}</>
                                    }
                                </Translation></Link>
                        </div> */}
                    </div>
                </div>
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        alertMessage: state.auth.alertMessage,
        isEn: state.lang.lang == "en" || typeof state.lang.lang == "undefined"
    };
}


const mapDispatchToProps = {
    loginAction,
    setAlert,
    setLang
}
export default connect(mapStateToProps, mapDispatchToProps) (Login);

