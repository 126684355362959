import { GET_ALL_PHARMACY } from "../actions/type";

const initialState = {list:[]};

const pharmacy = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PHARMACY:
      return {
        ...state,
        list: payload
      };

    default:
      return state;
  }
};

export default pharmacy;