import React from 'react'

class FilledButton extends React.Component {
    handleClick(e){
   
        if (this.props.disabled) {
            return;
        }

        this.props.action();
    }

    render() {
        if(this.props.hidden){
            return (null);
        }
        let className = this.props.id + " filled-button";
        if (this.props.styles) {
            this.props.styles.forEach (function (style) {
               className += " " + style;
            });
        }

        if (this.props.disabled) {
            className += ' disabled'
        }

        return (
            <button className={className} onClick={this.handleClick.bind(this)} ><div className={"filled-button-font"}>{this.props.children}</div></button>
        )
    }
}

export default FilledButton;