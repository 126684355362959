import React from 'react';

class DarkenedOverlay extends React.Component {
    select() {
        if (!this.props.onClick) {
            return
        }

        this.props.onClick();
    }

    render() {
        if (this.props.isHidden) {
            return (<div onClick={this.select.bind(this)}>{this.props.children}</div>);
        }

        return (
            <div onClick={this.select.bind(this)} className="overlay-background">
                {this.props.children}
            </div>
        );
    }
}

export default DarkenedOverlay;