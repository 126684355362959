import { medicalInfoConstant, userConstant } from '../Constants';

export function medicalInfo(state = {}, action) {
    switch (action.type) {
        case medicalInfoConstant.GET_BY_PERSON:
            return {
                ...state,
                medicalInfoObj: { ...action.medicalInfoObj },
                noMedicalInfo: action.noMedicalInfo,
                pastMedicalInfoObjs: action.pastMedicalInfoObjs,
                askmedUser:action.askmedUser
            }
        case medicalInfoConstant.GET_BY_ID:
            return {
                ...state,
                medicalInfoObj: { ...action.medicalInfoObj },
                noMedicalInfo: action.noMedicalInfo
            };
        case medicalInfoConstant.CHANGE:
            return {
                ...state,
                status: 'changed',
                medicalInfoObj: { ...action.medicalInfoObj }
            };
        case medicalInfoConstant.SAVE:
            return {
                ...state,
                medicalInfoObj: { ...action.medicalInfoObj },
                noMedicalInfo: false
            };
        case userConstant.LOGOUT:
        case userConstant.BACK_TO_DASHBOARD:
            return {};
        default:
            return state;
    }
}