import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";

//components
import Modal from "./layout/Modal";
import Alert from "./layout/Alert";
import AlertDialog from "./layout/AlertDialog";
import CountryList from "./layout/CountryList";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

//Actions
import { setAlert } from "../actions/alert";
import { loadUser, setUser, setShouldUpdate } from "../actions/auth";
import { setToLoaded, setToLoading } from "../actions/loader";
import { sickNoteAction } from '../actions/sicknote.action';

import { history } from '../_helpers';
// services

import Util from "../services/Utility";
import InputVerifier from "../services/InputValidationService";
import "react-datepicker/dist/react-datepicker.css";

// constants
// import config from '../config';

class SickNote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      reason: "",
      note: "",
      isAgree: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  setStartDate(startDate) {
    this.setState({
      startDate,
    });
  }

  setEndDate(endDate) {
    this.setState({
      endDate,
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    this.props.validateSickNote({
        timeOffIsFor: this.state.reason,
        timeOffReason: this.state.note, 
        startTimeOff: this.state.startDate, 
        endOfTimeOff: this.state.endDate,
        isAgreed: this.state.isAgree
    })

  }

  onCancel() {
    console.log("go back to list");
    history.push('dashboard')
  }
  componentDidUpdate() {
    if (
      moment(this.state.endDate).isAfter(moment(this.state.startDate).add(6, "days"))
      || moment(this.state.endDate).isBefore(moment(this.state.startDate))
    ) this.setEndDate(moment(this.state.startDate).toDate());
  }

  isTwelveOlder(){
    const { year, month, day } = this.props.user.birthday;
    let dob = moment(`${year}-${month}-${day}`);
    let years = moment().diff(moment(dob),'years');
    if ( years > 12){
      return true;
    } else {
      return false;
    }
  }
  render() {
    let reason = "";
    if (this.state.reason) {
      reason += "from ";
      if (this.state.reason == "work")
        reason += "work";
      if (this.state.reason == "school")
        reason += "school";
      if (this.state.reason == "workAndSchool")
        reason += "work and school";
    }
    return (
      <div className="sick-note-container">
        <Container>
          <div className="mt-3">
            <h1 className="keywords">Sick Note Request</h1>
            <p>Please answer the questions below: </p>
          </div>
          <Form className="mt-1">
            <Form.Group className="mb-1">
              <Form.Label>
                Is the time off from school, work, or both?
              </Form.Label>
              {this.isTwelveOlder()? <select
                className="form-control"
                value={this.state.reason}
                onChange={({ target: { value } }) =>
                  this.setState({ reason: value })
                }
              >
                <option value="">Select option</option>
                <option value="work">Work</option>
                <option value="school">School</option>
                <option value="workAndSchool">Work and school</option>
              </select>:  <select
                className="form-control"
                value={this.state.reason}
                onChange={({ target: { value } }) =>
                  this.setState({ reason: value })
                }
              >
                <option value="">Select option</option>
                <option value="school">School</option>
              </select>}
            </Form.Group>

            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Start of the time off (IMPORTANT: cannot be changed later):</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={this.state.startDate}
                    onChange={(date) => this.setStartDate(date)}
                    minDate={moment()
                      .subtract(60, "days")
                      .toDate()}
                    maxDate={moment().add(1,"days").toDate()} 
                    dateFormat="dd MMM yyyy"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>End of time off (IMPORTANT: cannot be changed later):</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={
                      this.state.endDate
                    }
                    onChange={(date) => this.setEndDate(date)}
                    minDate={moment(this.state.startDate).toDate()}
                    maxDate={moment(this.state.startDate)
                      .add(6, "days")
                      .toDate()}
                    dateFormat="dd MMM yyyy"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-1">
              <Form.Label>
                {`Please explain the reason for you being away ${reason} between ${moment(this.state.startDate).format("DD MMM yyyy")} and ${moment(this.state.endDate).format("DD MMM yyyy")}:`}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                maxLength="400"
                value={this.state.note}
                onChange={(e) => {
                  this.setState({
                    note: e.target.value,
                  });
                }}
                placeholder="Provide reason"
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Check>
                <Form.Check.Input id="agreement" type="checkbox" checked={this.state.isAgree} className = "big-checkbox"
                  onChange={() => {
                    this.setState({ isAgree: !this.state.isAgree });
                  }}/>
                <Form.Check.Label for="agreement" className="checkbox-label">{'I declare that I am truthful about the information provided to the South Vancouver Medical Clinic doctor. If I have severe symptoms that warrant a visit to the doctor, such as significant pain or difficulty breathing, I agree to follow up with a doctor immediately by booking at SouthVanMed.com/login or going to the hospital emergency department.  I also agree with sick note fees being non-refundable and that the sick note may be modified by the doctor if deemed necessary.'}</Form.Check.Label>
              </Form.Check>
            </Form.Group>

            <Row>
              <Col className="d-flex justify-content-between">

              <Button
                  variant="secondary"
                  onClick={this.onCancel}
                  className="w-25 form-control"
                >
                  Cancel
                </Button>
                
                <Button
                  variant="primary"
                  onClick={this.onSubmit}
                  className="w-25 form-control"
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {
  loadUser,
  setUser,
  setShouldUpdate,
  setAlert,
  setToLoading,
  setToLoaded,
  validateSickNote: sickNoteAction.validateSickNote
};

export default connect(mapStateToProps, mapDispatchToProps)(SickNote);
