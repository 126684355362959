import React from 'react'
import {Container, Col, Row} from "react-bootstrap";
import { Redirect } from 'react-router-dom'
import {passwordResetAction, sendResetCodeAction,forgetPasswordValidate} from '../actions//auth'
// import Subheader from '../Components/layout/Subheader'
import FilledButton from './button/FilledButton'
import OutlineButton from './button/OutlineButton'
import AlertDialog from './layout/AlertDialog'

// import RouteConstants from '../Constants/RouteConstants'
import { connect } from 'react-redux'
import DayDropDown from './layout/DayDropDown'
import MonthDropDown from './layout/MonthDropDown'
import YearDropDown from './layout/YearDropDown'
import PasswordConstants from '../Constants/PasswordConstants'
import config from '../config'
import SpinningControl from './layout/SpinningControl'
import ReCAPTCHA from 'react-google-recaptcha';
import { scroller } from 'react-scroll';

const ForgotPasswordButton = (props) => {
    return(
    <FilledButton styles={props.styles} action={props.action}>{props.buttonText}</FilledButton>
    )
} 


class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            passwordRecoveryState:PasswordConstants.identify,
            isWorking: false,
            alertMessage: '',
            isSent: false,
            email: '',
            code: '',
            password: '',
            confirmPassword:'',
            isSuccess: false,
            birthDay:'',
            birthMonth:'',
            birthYear:'',
            hin:'',
            isDone:false,
            recaptcha:""
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this._scrollToTop();
    }


    _scrollToTop(){
        scroller.scrollTo("password-reset-container", {
            duration: 800,
            smooth: true,
            offset: -100
        });

    }

    closeAlert() {
        // this.props.updateMessageAction('');
        this.setState({
            alertMessage:''
        })
        
        if(this.state.isDone){
            this.setState({
                isSuccess:true
            })
        }
    }
   async handleButton() {

        this.setState({
            isWorking:true
        })


        if(this.state.passwordRecoveryState==PasswordConstants.identify){
            if(this.state.email.length < 1){
                this.setState({
                    alertMessage:"Email input cannot be empty.",
                    isWorking:false
                })
                return;
            }
            if(!this.state.birthDay || !this.state.birthMonth || !this.state.birthYear){
                this.setState({
                    alertMessage:`Date of birth is invalid, Contact us at ${config.service_email} if you continue having difficulty with your password.`,
                    isWorking:false
                })
                return;
            }
            if(!this.state.recaptcha){
                this.setState({
                    alertMessage:"Please verify you are not a bot.",
                    isWorking:false
                })
                return;
            }
            try{
                let res = await this.props.forgetPasswordValidate(this.state.email,this.state.birthDay,this.state.birthMonth,this.state.birthYear,this.state.hin,this.state.recaptcha);

                if(!res.success){
                    this.setState({
                        alertMessage:res.extras.message || 'Failed validating your information.',
                        isWorking:false,
                        recaptcha:""
                    })
                    return;
                }

                this.setState({
                    passwordRecoveryState:PasswordConstants.identifySuccess,
                    isWorking:false
                })
                return;


            }catch(err){
                this.setState({
                    alertMessage:"Server error",
                    isWorking:false,
                    recaptcha:""
                })
            }

        }

        switch (this.state.isSent) {

            case true: {
                if (this.state.email.length < 1) {
                    this.setState({
                        alertMessage:'Email input cannot be empty.',
                        isWorking:false
                    })
                    return;
                }
                if (this.state.code.length < 1) {
                    this.setState({
                        alertMessage:'Input Code input cannot be empty.',
                        isWorking:false
                    })
                    return;
                }
                if (this.state.password.length < 1) {
                    this.setState({
                        alertMessage:'Password input cannot be empty.',
                        isWorking:false
                    })
                    return;
                }

                if(this.state.password.length < 8){
                    this.setState({
                        alertMessage:'The length of password must be greater than 8.',
                        isWorking:false
                    })
                    return;
                }

                if(this.state.password!==this.state.confirmPassword){
                    this.setState({
                        alertMessage:'New Password and Confirm New Password do not match.',
                        isWorking:false
                    })
                    return;
                }


                try{
              
                    let email = this.state.email.split(" ").join("").toLowerCase();
                    let res = await this.props.sendResetCodeAction(email, this.state.code, this.state.password);
                    let status = res.status || false;
                    this.setState({
                        isDone:status
                    })
                    if(status){

                        let counter = 5;
                        this.interval = setInterval(() => {
                            counter--;
                            if(counter < 0){
                                this.setState({
                                    isSuccess:true
                                })
                            }

                            this.setState({
                                isWorking:false,
                                alertMessage:`Your password has been reset successfully. You will be redirected to the login page in ${counter} seconds.`
                            })
                        },1000)

                        return;
                    }
                    // console.log(res)
                    this.setState({
                        isWorking: false,
                        alertMessage: res.extras.message || 'An unknown error has occurred.'
                    });


                }catch(err){
                    console.log(err)
                      this.setState({
                        isWorking: false,
                        alertMessage: 'An unknown error has occurred.'
                    });
                }
                break;
            }
            default: {
                if (this.state.email.length < 1) {
                    this.setState({ 
                        alertMessage: 'Email input cannot be empty.', 
                        isWorking: false
                    });
                    return 
                }

                let email = this.state.email.split(" ").join("").toLowerCase();
                try{
                    let res = await this.props.passwordResetAction(email);
                    let status = res.status || false;

                    if(status){
                        this.setState({
                            isWorking: false,
                            isSent: status,
                            passwordRecoveryState:PasswordConstants.reset
                        });
                        return;
                    }
                    this.setState({
                        isWorking: false,
                        isSent: status,
                        alertMessage:res.extras.message
                    });
                    
                    return;

                }catch(err){
                    this.setState({
                        isWorking: false,
                        isSent: false,
                        alertMessage: 'Unknown error occured.'
                    });
                }
                break;
            }
        }
    }
    handleChange(event) {
        if(event.target.name =='hin'){
            let re =  /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)){
                return this.setState({
                    [event.target.name]: event.target.value
                })
            }
            return;
        }
        this.setState({ [event.target.name]: event.target.value })
    }
    handleReCaptcha(val){
        this.setState({
            recaptcha:val
        })
    }

    cancelButton(){
        this.setState({
            passwordRecoveryState:PasswordConstants.identify,
            recaptcha:""
        })
    }

    renderCodeInput() {
        return (
            <>
            <Row>
                <Col sm={12} md={4}>
                    <div className="color-charco">Reset Code *</div>
                    <input
                        className="input-full-width"
                        type="text"
                        name="code"
                        onChange={this.handleChange.bind(this)}
                        value={this.state.code}
                        placeholder="Reset Code"
                    />
                </Col>
                <Col sm={12} md={4}>
                    <div className="color-charco">New Password *</div>
                    <input
                    className="input-full-width"
                    type="password"
                    name="password"
                    onChange={this.handleChange.bind(this)}
                    value={this.state.password}
                    placeholder="New Password"
                />
                </Col>
                <Col sm={12} md={4}>
                    <div className="color-charco">Confirm New Password *</div>
                    <input
                    className="input-full-width"
                    type="password"
                    name="confirmPassword"
                    onChange={this.handleChange.bind(this)}
                    value={this.state.confirmPassword}
                    placeholder="Confirm New Password"
                />
                </Col>
            </Row>
            <Row>
                <Col>
                <br/>
                    <ForgotPasswordButton
                        styles={['pull-right']}
                        buttonText="Reset My Password"
                        action={this.handleButton.bind(this)}
                    />
                </Col>
            </Row>
            </>
        )
    }

    renderTitle(){
        switch(this.state.passwordRecoveryState){

            case PasswordConstants.identify:
            case PasswordConstants.identifySuccess:
                return (
                    <>
                    <h1 className="main-color">Password Recovery</h1><p>Fill out the form below to confirm your identity and then recover your password.</p>
                    </>
                )
            case PasswordConstants.reset:
                return (
                    <>
                    <h1>Forgot Password</h1><p>Please enter the reset code you recieved in your email to reset your password.</p>
                    </>
                )
            
            default:
                return (
                    <>
                    <h1>Forgot Password</h1><p>Please enter your email address to receive a password reset code in your inbox.</p>
                    </>
                )

        }
    }

    renderButtonText(){
        switch(this.state.passwordRecoveryState){

            case PasswordConstants.identify:
                return `Validate my information`
            case PasswordConstants.identifySuccess:
                return 'Confirm'
            case PasswordConstants.reset:
                return `Reset Password`
            
            default:
                return 'Reset Password'
    }
}



    render() {
        if (this.state.isSuccess) {
            return <Redirect to='/login'></Redirect>;
        }
        return (
            <section id="password-reset-container">
                <AlertDialog
                    message={this.state.alertMessage}
                    onClose={this.closeAlert.bind(this)}
                />
                {this.state.isWorking ? <SpinningControl /> :
                <Container>
                    <div className="form-head">
                    {this.renderTitle()}
                    </div>

                {
                this.state.isSent ?   
                this.renderCodeInput():
                <>
                <Row>
                    <Col sm={12} md={12} lg={4}>
                        <div>&nbsp;</div>   
                        <div className="color-charco">Email *</div>
                        <input
                            className="input-full-width"
                            type="text"
                            name="email"
                            onChange={this.handleChange}
                            value={this.state.email} 
                            placeholder="Email"
                        />
                    </Col>
                    <Col sm={12} md={12} lg={4}>
                        <Row>
                            <Col>
                                <div>&nbsp;</div>
                                <div className="color-charco"> Date of Birth *</div>
                            </Col>
                        </Row>
                        <Row>    
                            <Col sm={12} md={4} >
                            <DayDropDown
                                classStyle="pull-left ml-1 input-full-width"
                                name="birthDay"
                                value={this.state.birthDay}
                                onChange={this.handleChange}
                            />
                            </Col>                        
                           
                           <Col sm={12}  md={4}>
                            <MonthDropDown
                                classStyle="pull-left ml-1 input-full-width"
                                name="birthMonth"
                                value={this.state.birthMonth}
                                onChange={this.handleChange}
                            />
                            </Col>

                            <Col sm={12} md={4}>
                            <YearDropDown
                                classStyle="pull-left ml-1 input-full-width"
                                value={this.state.birthYear}
                                name="birthYear"
                                onChange={this.handleChange}
                            />
                            </Col>
                        
                        </Row>

                    
                    </Col>

                    <Col sm={12} md={12} lg={4}>
                        <div className="color-charco">B.C. Services Card number (leave empty if you do not have a B.C. Services Card)</div>
                        <input
                            className="input-full-width"
                            type="text"
                            name="hin"
                            onChange={this.handleChange}
                            value={this.state.hin} 
                            placeholder="Healthcard Number"
                        />
                    </Col>
                </Row>
                {this.state.passwordRecoveryState==PasswordConstants.identify ? 
                <Row>
                    <Col sm={12}>
                        <br/>
                        <div style={{float:'right'}}>
                            <ReCAPTCHA
                                sitekey={config.captcha_key}
                                onChange={this.handleReCaptcha.bind(this)}
                            />
                        </div>
                    </Col>
                </Row> : null
                }
                
                    <Col sm={12}>
                    {this.state.passwordRecoveryState==PasswordConstants.identifySuccess ? <>
                        <hr />
                        
                        <div className="form-head">
                        <h1>User Found</h1><p>Please confirm that you want to reset your password. A password reset code will be sent to the email we have on file.</p>
                        </div>
                        </> : null
                    }
                    </Col>
                <Row>
                    <Col sm={12}>
                        <br/>
                        {this.state.passwordRecoveryState==PasswordConstants.identifySuccess ? 
                        
                        <OutlineButton 
                            action={this.cancelButton.bind(this)} 
                            styles={['pull-right','ml-1']}
                        >Cancel</ OutlineButton >
                        : null }
                        

                        <ForgotPasswordButton 
                            state={this.props} 
                            action={this.handleButton.bind(this)} 
                            buttonText={this.renderButtonText()}
                            styles={['pull-right']}
                        />
                    </Col>
                </Row>
                </>}
                        </Container>}                
            </section>

        )
    }
}

const mapDispatchToProps = {
    passwordResetAction,
    sendResetCodeAction,
    forgetPasswordValidate
}
export default connect(null, mapDispatchToProps) (ForgotPassword);
