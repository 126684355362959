export const getLang = () => {
    return localStorage.getItem('lang') || "en";
}

export const setLang = (lang = "en") => {
    return localStorage.setItem("lang", lang);
}

export const switchLang = () => {
    if (getLang() == "en")
        return setLang("cn");
    return setLang("en");
}

export const switchLangReload = () => {
    switchLang();
    window.location.reload();
}

export const isEn = () => {
    return getLang() == "en";
}

export const getLangCode = () => {
    return isEn() ? "en" : "zh-TW"
}