
import axios from 'axios';
import config from '../config';


export const messagesService = {
    getMessagesByPerson,
    sentMessageToMOA,
    getMessageById,
    updateMessageById,
    updateReplies,
    updateRepliesByPopUp
};

async function getMessagesByPerson(limit, skip) {
    const url = `${config.service}/message/?limit=${limit}&skip=${skip}`;
    let token = localStorage.getItem('accessToken');
    axios.defaults.headers.common['x-access-token'] = token;
    const resp = await axios.get(url);
    return resp.data.messages;
}

async function sentMessageToMOA( message) {
    const url = `${config.service}/message/sent`;
    let token = localStorage.getItem('accessToken');
    axios.defaults.headers.common['x-access-token'] = token;
    const body = { message}
    const res= await axios.post(url, body);
    
    return res.data;
}

async function getMessageById(id) {
    const url = `${config.service}/message/${id}`;
    let token = localStorage.getItem('accessToken');
    axios.defaults.headers.common['x-access-token'] = token;
    const resp = await axios.get(url);
    return resp.data.message;
}

async function updateMessageById(id, message) {
    const url = `${config.service}/message/${id}`;
    let token = localStorage.getItem('accessToken');
    axios.defaults.headers.common['x-access-token'] = token;
    const resp = await axios.put(url, { message });
    return resp.data.message;
}


function updateReplies(id, sendMessageObj) {
  
    const url = `${config.service}/message/${id}`;
    var bodyFormData = new FormData();

    bodyFormData.append('message', sendMessageObj.message);
    
    if(sendMessageObj.file1 && sendMessageObj.file1.length >0){
        console.log(sendMessageObj)
        for(let i = 0; i < sendMessageObj.file1.length; i++){
            let file = sendMessageObj.file1[i];
            bodyFormData.append('files', file)
        }
    }

    if(sendMessageObj.file2 && sendMessageObj.file2.length >0){
        
        for(let i = 0; i < sendMessageObj.file2.length; i++){
            let file = sendMessageObj.file2[i];
            bodyFormData.append('files', file)
        }
    }

    if(sendMessageObj.file3 && sendMessageObj.file3.length >0){
        
        for(let i = 0; i < sendMessageObj.file3.length; i++){
            let file = sendMessageObj.file3[i];
            bodyFormData.append('files', file)
        }
    }


    let axiosConfig = {headers: {'Content-Type': 'multipart/form-data'}}
    return axios.put(url, bodyFormData,axiosConfig);
}


function updateRepliesByPopUp(token, sendMessageObj) {
  
    const url = `${config.service}/sick-note/message/update-replies?token=${token}`;
    var bodyFormData = new FormData();

    bodyFormData.append('message', sendMessageObj.message);
    
    if(sendMessageObj.file1 && sendMessageObj.file1.length >0){
        console.log(sendMessageObj)
        for(let i = 0; i < sendMessageObj.file1.length; i++){
            let file = sendMessageObj.file1[i];
            bodyFormData.append('files', file)
        }
    }

    if(sendMessageObj.file2 && sendMessageObj.file2.length >0){
        
        for(let i = 0; i < sendMessageObj.file2.length; i++){
            let file = sendMessageObj.file2[i];
            bodyFormData.append('files', file)
        }
    }

    if(sendMessageObj.file3 && sendMessageObj.file3.length >0){
        
        for(let i = 0; i < sendMessageObj.file3.length; i++){
            let file = sendMessageObj.file3[i];
            bodyFormData.append('files', file)
        }
    }


    let axiosConfig = {headers: {'Content-Type': 'multipart/form-data'}}
    return axios.put(url, bodyFormData,axiosConfig);
}

