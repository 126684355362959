import React from 'react';


import {PropagateLoader} from 'react-spinners';

const PropagateLoaderWrapper = props => {

    const {isLoading} = props;


    return (
        <>
            <PropagateLoader
                color="#42B3E5"
                size={6}
                loading={isLoading ? true : false}
            />
        </>
    )
}


export default PropagateLoaderWrapper;