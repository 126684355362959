import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { messageAction } from '../actions/message.action';


import AlertBox from '../components/layout/Alert';

import {  formatDateTime } from '../_helpers';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import FileUploaderController from './file/file-uploader-controller';





class ReplyMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen :true,
            isOpenPopUp: true,
            isButtonDisabled:false,
            fullScreen:false
        };
    }

    componentDidMount() {
        // seo({
        //     title: 'Message - South Vancouver Medical Clinic',
        //     metaDescription: 'With some meta description'
        // });
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    resize(){
        this.setState({fullScreen: window.innerWidth <= 500});

    }


    handleDiscard = () => {
         this.setState({isOpen:false});
         window.close();
    }

    handleChange = event => {
        const { dispatch, sendMessageObj } = this.props;

        sendMessageObj.message = event.target.value;

        // dispatch messageAction change
        dispatch(messageAction.change(sendMessageObj));
    };

    /**
     * Get the file on the input file
     */
    changeFile = (files, fileKey) => {
        const { dispatch, sendMessageObj } = this.props;
        sendMessageObj[fileKey] = files;
        dispatch(messageAction.change(sendMessageObj));

    }

    isNoAttachment() {
        let {sendMessageObj} = this.props;
        let file1 = sendMessageObj.file1;
        let file2 = sendMessageObj.file2;
        let file3 = sendMessageObj.file3;
        const _isFileEmpty = (f)=>{
            return !f || f.length===0;
        }

        let isNoAttachment = _isFileEmpty(file1) && _isFileEmpty(file2) && _isFileEmpty(file3);

        return isNoAttachment;
    }

    validate = () => {
        const {messageObj,sendMessageObj} = this.props;
        if(!sendMessageObj.message){
            alert('Message cannot be empty');
            return false;
        }

        // need attachment
        if(messageObj.requestImage){
            // console.log(files)
            if(this.isNoAttachment()){
                alert('Please attach at least one picture.');
                return false;
            }
            
        }

        return true;
    }

    handleSendReplyByLink = async (event) => {
        
        try{
            event.preventDefault();
            const { dispatch, sendMessageObj } = this.props;
            if(!this.validate()) return;
            
            this.setState({ 
                isButtonDisabled:true
            })
    
            const token = localStorage.getItem('messageCode');
           
            let res = await dispatch(messageAction.sendReplyByPopUp(token, sendMessageObj));
            debugger
            if(res.success){
                this.setState({
                    isOpen:false,
                    isButtonDisabled:false
                });
                setTimeout(() => {
                   window.close();
                }, 3000)
                return;
            }
            
            this.setState({
                isButtonDisabled:false
            })
            alert(res.extras.message);
            window.close();
           

        }catch(e){
            console.log(e)
            this.setState({
                isButtonDisabled:false
            })
            alert('Server error, please try again later');
        }
    }

    handleClose = () => {
        this.handleDiscard();
    }

    handlePopUpClose = () => {
        this.setState({
            isOpenPopUp:false
        })
        window.close();
    }

    renderMessage(from, date, message, files) {
        const newMessage = 'Dear patient, '+ message +".";
        return (
            <>
                <div className="title">
                    <div className="person">
                        From: {from}
                    </div>
                    <div className="date">
                        {formatDateTime(date)}
                    </div>
                </div>
                <div className="content-message">
                    {newMessage}
                </div>

            </>
        );
    }

    renderReplyV2() {

        const { sendMessageObj, messageObj } = this.props;
        const { isOpen } = this.state;

        let title = `Reply to the doctor's message`;
        if (messageObj.isMOA){
            title = `Reply to the medical office assistant's message`;
        }
        if(messageObj && messageObj.requestImage){
            title = title + ' '+ 'and attach one to three pictures or files'
        }

        let sendBtnText = this.state.isButtonDisabled ? 'Sending message ...' : 'Send';

        const displayMessageBox = messageObj ?
    <div id="reply-box" className="container">
        <Dialog
            fullWidth={true}
            fullScreen={this.state.fullScreen}
            maxWidth="sm"
            open={isOpen}
        >
            <DialogTitle className="reply-box-title">
            {title}
               {/* <h2 className=""></h2>  */}
            </DialogTitle>
            <DialogContent>
        <div id="message">

        {this.renderMessage(messageObj.from, messageObj.createdAt, messageObj.message, messageObj.files)}
        </div>
        <form noValidate>
            <div className="form-group">
                <textarea className="form-control" id="message" name="message" rows="4" placeholder="reply..."
                    onChange={this.handleChange}
                    value={sendMessageObj.message}
                    maxlength="400"
                    autoFocus
                    disabled={this.state.isButtonDisabled}
                >
                </textarea>
                <br />
               {messageObj.requestImage ? <FileUploaderController changeFile={this.changeFile} multiple={false} isButtonDisabled={this.state.isButtonDisabled} isNoAttachment={this.isNoAttachment.bind(this)}/> : null} 

            </div>
        </form>
            </DialogContent>
            <DialogActions> 
                
            <button className="btn btn-primary mr-2" onClick = {(e) =>this.handleSendReplyByLink(e)} disabled={this.state.isButtonDisabled}>{sendBtnText}</button>
            <button className="btn btn-secondary " onClick={this.handleDiscard} disabled={this.state.isButtonDisabled}>Close</button>
            </DialogActions>
        </Dialog>
    </div>: "";
        return displayMessageBox;
        

    }

    renderReplyPopUP(){
   
        const { isOpenPopUp } = this.state;
        return(
        <div className="container">
                <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={isOpenPopUp}
                >

                    <DialogTitle>
                    You have already responded to this message.
                    </DialogTitle>
                    <DialogContent>
                        <p>
                        If your require any further assistance, please email info@instoremed.com.
                        </p>
                    </DialogContent>
                    <DialogActions>
                    <button className="btn btn-primary ml-2" onClick={this.handlePopUpClose} >Close</button>
                    </DialogActions>
                </Dialog>
            </div>
        )

    }


    render() {
        const { isReplied } = this.props;
        const displayContent = isReplied ? this.renderReplyPopUP():this.renderReplyV2()
        return (
            <div id="message-reply-page">
                <div>
                    <h1 className= "text-center mt-20"> Message Reply Page </h1>
                    <AlertBox></AlertBox>
                </div>
                {displayContent}
            </div>         
        )
    }
}


ReplyMessage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    sendMessageObj: PropTypes.object,
    classes: PropTypes.object,
};


function mapStateToProps(state) {
    let { sendMessageObj, isReplied, messageObj } = state.message;

    if (messageObj === undefined) {
        messageObj = {
            message: ''
        };
    }

    if (sendMessageObj === undefined) {
        sendMessageObj = {
            message: ''
        };
    }
    return {
        sendMessageObj,
        isReplied,
        messageObj
    };
}


// const temp = compose(
//     connect(mapStateToProps),
//     withStyles(styles)
// )(ReplyMessage);

const temp = connect(mapStateToProps, null)(ReplyMessage);

export { temp as ReplyMessage };

