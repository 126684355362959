import React from 'react'

class AlertDialog extends React.Component {
    render() {
        return (
            <div className="alert-box" style={{display: this.props.message.length > 0 ? 'block' : 'none'}}>
                <div className="alert-box-content">
                    <span className="close" onClick={this.props.onClose} style={{display: this.props.disableClose ? 'none' : 'block'}}>&times;</span>
                    <p className="alert-box-text" dangerouslySetInnerHTML={{__html: this.props.message}}/>
                </div>
            </div>
        )
    }
}

export default AlertDialog;