import React from 'react';
import Modal from 'react-bootstrap/Modal';

import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Button from 'react-bootstrap/Button'

const ModalComponent = (props) => {

    const cancelFunc = props.cancelFunc ? props.cancelFunc : props.setShow;
    
    let closeButton = true;
    
    if(props.closeButton!==undefined && 
        (props.closeButton===true || 
        props.closeButton===false)
        )
    {
            closeButton = props.closeButton;
    }

    let backDrop = true;
    if(props.backdrop!==undefined && 
        (props.backdrop===true || 
        props.backdrop===false) ||
        props.backdrop==='static'
        )
    {
            backDrop = props.backdrop;
    }
    let keyboard = true;
    if(props.keyboard!==undefined && 
        (props.keyboard===true || 
        props.keyboard===false) 
        )
    {
        keyboard = props.keyboard;
    }
        return (
            <Modal
                show={props.show}
                onHide={() => props.setShow(false)}
                size="lg"
                backdrop={backDrop}
                keyboard={keyboard}
                dialogClassName={props.dialogClassName || ''}
                //aria-labelledby="example-custom-modal-styling-title"
            >
                <ModalHeader closeButton={closeButton}>
                    <ModalTitle id="example-custom-modal-styling-title">
                        {props.header}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    {props.message}
                </ModalBody>
                <ModalFooter className={props.footerClass}>
                    {!props.hideCloseButton ? <Button variant="secondary"onClick={e=>cancelFunc(false)}>{props.cancelText}</Button> : null}
                    {!props.hideConfirmButton ? <Button variant="primary" onClick={props.confirm}>{props.confirmText}</Button> : null }
                </ModalFooter>
            </Modal>
    
        )

}

export default ModalComponent;