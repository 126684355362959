import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';


const PrivateRoute = (props) => {
    

    const { component, isAuthenticated, ...rest } = props;
    const Component = component;

    return (
        <Route {...rest} render={props => (
            isAuthenticated
                ? (
                    <Component {...props} />
                )
                : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
        )} />
    );
};

const mapStateToProps_private = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        // user: state.auth.user,
        // accessToken: state.auth.token
    };
}
export default connect(mapStateToProps_private)(PrivateRoute);
