/* loader store */

import {LOADER_LOADED,LOADER_LOADING} from '../actions/type';

const initialState = {
    isLoading:false
};

export default (state = initialState,action) => {
    const {type} = action;
    switch(type){
        case LOADER_LOADING:
            //set to loading
            return {
                ...state,
                isLoading:true
            }
        case LOADER_LOADED:
            // set to loaded
            return {
                ...state,
                isLoading:false
            }
        
        default:
            return state;
    }
}