import { userConstant } from '../Constants';
import store  from '../store';

// export const isAuth = () => {
//     if (getToken() && sessionStorage.getItem('user')) {
//         return true;
//     } else {
//         return false;
//     }
// };

export const isAuth = () => {
    if (getToken() && getUser()) {
        return true;
    } else {
        return false;
    }
}

export const clearSessionStorage = () => {
    sessionStorage.clear();
};

export const getUser = () => {
    const state = store.getState();
    const { user } = state.authentication;
    return user;
};

export const getAskmedUser = () => {
    const state = store.getState();
    const { askmedUser } = state.medicalInfo;
    return askmedUser;

}

export const getToken = () => {
    return sessionStorage.getItem('token');
};

export const setToken = (token) => {
    return sessionStorage.setItem('token', token)
}

export const setIsDashBoard = (isDashBoard = false) => {
    return sessionStorage.setItem('isDashBoard', isDashBoard)
}

export const getIsDashBoard = () => {
    return sessionStorage.getItem('isDashBoard')
}

export const isDoctor = () => {
    return getAskmedUser().Role === userConstant.ROLE_DOCTOR;
};

export const isPatient = () => {
    return getAskmedUser().Role === userConstant.ROLE_PATIENT;
};

export const isAdmin = () => {
    return getAskmedUser().isAdmin ? true : false;
}

export const isCustomerService = () => {
    return getAskmedUser().Role === userConstant.ROLE_CUSTOMERSERVICE;
}

export const getPersonId = () => {
    return getAskmedUser().Person._id;
};

export const getPersonName = () => {
    const person = getAskmedUser().Person;
    return `${person.FirstName} ${person.LastName}`;
};

export const getPerson = () => {
    return getAskmedUser().Person;
};

export const isPrivatePay = () => {
    return getAskmedUser().Person.isPrivatePay ? true : false;
}