// import moment from "moment";
// import "moment-timezone";
import config from '../config';
import axios from 'axios';
// import * as Sentry from '@sentry/react';
import DayLightConst from '../Constants/DaylightSavingConstant';

class Util {
  
  noMobileHomeIntFound(items){
    return !(items.mobilePhone1&&items.mobilePhone2&&items.mobilePhone3) && !(items.phone1&&items.phone2&&items.phone3) && !items.internationalPhone;
  }

  phoneEntryValidate(items,errors) {

    if(this.noMobileHomeIntFound(items)){
      if(!errors.includes("mobilePhone1")){
        errors.push("mobilePhone1")
      }
      if(!errors.includes("mobilePhone2")){
        errors.push("mobilePhone2")
      }
      if(!errors.includes("mobilePhone3")){
        errors.push("mobilePhone3")
      }

      if(!errors.includes("phone1")){
        errors.push("phone1")
      }
      if(!errors.includes("phone2")){
        errors.push("phone2")
      }
      if(!errors.includes("phone3")){
        errors.push("phone3")
      }
      if(!errors.includes("internationalPhone")){
        errors.push("internationalPhone")
      }
      return errors;
    }else{
      errors = errors.filter(e => {
        if(e==="mobilePhone1" || e==="mobilePhone2" ||e==="mobilePhone3"
        || e==="phone1" || e==="phone2"|| e==="phone3"
        || e==="internationalPhone"){
          return false;
        }
        return true;
      })
    }
    // console.log(errors)

    return errors;
  }
  phoneEntryValidateMyAccount(items,errors){
    if(!items.mobilePhone && !items.phone && !items.internationalPhone){
      if(!errors.includes("mobilePhone")){
        errors.push("mobilePhone")
      }
      if(!errors.includes("phone")){
        errors.push("phone")
      }
      if(!errors.includes("internationalPhone")){
        errors.push("internationalPhone")
      }
    }else{
     errors = errors.filter(e=>{
        if(e==="mobilePhone" || e==="phone" || e==="internationalPhone"){
          return false;
        }
        return true;
      })
    }
    return errors;
  }
  // input is the string to be checked
  isNumber(input){
    let numbers = /^[0-9]+$/;
    return !!input.match(numbers);
  }
  
  // input is the string to be checked
  isCanadianPhoneNumber(input){
    let numbers = /^[1-9]\d{2}-\d{3}-\d{4}$/;
    return !!input.match(numbers);
  }

  applyInputStyles(validationErrors, name, styles) {
    var className = "";

    if(styles){
      styles.forEach(function (style) {
        className += " " + style;
      });
    }

    if (validationErrors.indexOf(name) > -1) {
      className += " input-error";
    }

    return className;
  }

  // convertToBClocalTime(lt) {
  //   //assume localTime is moment obj
  //   // let d = new Date(); //current date of browser
  //   // let localTime = d.getTime(); // convert to msec since jan 1 1970 - local time
  //   // let localOffset = d.getTimezoneOffset()* 60 * 1000 ;// obtain local UTC offset and convert to msec
  //   // let utc = localTime + localOffset;// obtain UTC time in msec
    
  //   // let vanOffset = -7;
  //   // let vancouver = utc + (vanOffset * 60 * 60 * 1000)
  //   // console.log(new Date(vancouver))
  //   let vanOffset = moment().tz("America/Vancouver").utcOffset() / 60;

  //   let now = moment();
  //   let daylightSaving = moment(DayLightConst[`2021`].start).add(2,'hours');
  //   // let daylightSavingDate = moment(DayLightConst[`2021`].start);
  //   if(now.isBefore(daylightSaving) && lt.isSameOrAfter(daylightSaving)){
  //     vanOffset ++;
  //   }

  //   let respectiveBCTime = lt.valueOf() - lt.utcOffset() * 60 * 1000 + (vanOffset * 60 * 60 * 1000);

  //   let val = moment(respectiveBCTime, 'x');
  //   return val;

  // }

  //Parameters are expected to be strings - * Details should be stringified if it's JSON 
  //Please see model of ErrorLog for details
 async saveErrorDetails(errCode, details) {
    let configAxios = {
      headers: {
        "Content-Type": "application/json"
      },
    }
    const url = `${config.service}/services/saveerror`;
    let data = { err: errCode, date: new Date(), details: details}
    try{
      await axios.post(url,data,configAxios);
    }catch(err){
      console.log(err)
    }
  }


  // charge with stripe

  async chargeNow(data) {
    try {
      // console.log(data)
      const url = `${config.service}/services/charge`;
      let configAxios = {
        headers: {
          "Content-Type": "application/json"
        },
      }
      
      let appointment = data.appointment;

      let body = { 
        stripeToken: data.token,
        selectedType:data.selectedType,
        providerId:data.providerId,
        amount:data.amount,
        start: appointment.first.start.format(),
        end:appointment.first.end.format(),
        doctor: appointment.first.doctor.id,
        reason: (appointment._storedAppointmentState || {}).reason || '',
        selectedType:appointment.first.code
      }
      // console.log(body)
      let res = await axios.post(url,body,configAxios);

      return res.data

    } catch (err) {
      // console.log(err);
      // Sentry.captureException(err);
      throw err;
    }
  }

  async getAppointmentsByUser() {
    try {
      const url = `${config.service}/me/appointments`;
      let res = await axios.get(url)
      return await res.data;
    } catch (err) {
      // Sentry.captureException(err);
      // console.log(err)
      throw err;
    }
  }


  //
  //@ get selected appointment states from server

  async getSelectedAppointmentState(eligibility,selectionType) {
    try {
      const url = `${config.service}/services/appstate?eligibility=${eligibility}&selectedType=${selectionType}`;

      let selectedAppointmentState = await axios.get(url);
      return selectedAppointmentState.data;

    } catch (err) {
      // console.log(err)
      // Sentry.captureException(err);
      throw err;
    }
  }

  async oscarStatus(){
    try {
      let res = await axios.get(`${config.service}/services/oscarStatus`)
      return res.data
    } catch (err){
      // Sentry.captureException(err);
      return {status:false};
    }
  }

  /*
  @ get demographic
  */
  async getDemographic(user){
    try{
      let configAxios = {
        headers:{
            id:user.id,
            hin: user.careCard.number || '',
            province: user.careCard.province || '',
            lastname:user.lastName,
            year:user.birthday.year,
            month:user.birthday.month,
            day:user.birthday.day
        }
    }
      let res = await axios.get(`${config.service}/demographics`,configAxios);
      return res.data;
    }catch(err){
      // Sentry.captureException(err);
      throw err;
    }
  }

  async createDemographic(user){
    try{
      let url = `${config.service}/demographics/create`
      let configAxios = {
        headers:{
          "Content-Type": "application/json"
        }
      };
      let data = {
        id:user.id,
        familyDoc:user.familyDoc
      }

      let res = await axios.post(url,data,configAxios)

      return res.data;

    }catch(err){
      // Sentry.captureException(err);
      throw err;
    }
  }

  minutesOfDay(mo){
    let m = this.convertToBClocalTime(mo);
    return m.minutes() + m.hours() * 60;
  }

 phone_formatting(ele,restore) {
    var new_number,
        selection_start = ele.selectionStart,
        selection_end = ele.selectionEnd,
        number = ele.value.replace(/\D/g,'');
    
    // automatically add dashes
    if (number.length > 2) {
      // matches: 123 || 123-4 || 123-45
      new_number = number.substring(0,3) + '-';
      if (number.length === 4 || number.length === 5) {
        // matches: 123-4 || 123-45
        new_number += number.substr(3);
      }
      else if (number.length > 5) {
        // matches: 123-456 || 123-456-7 || 123-456-789
        new_number += number.substring(3,6) + '-';
      }
      if (number.length > 6) {
        // matches: 123-456-7 || 123-456-789 || 123-456-7890
        new_number += number.substring(6);
      }
    }
    else {
      new_number = number;
    }
    
    // if value is heigher than 12, last number is dropped
    // if inserting a number before the last character, numbers
    // are shifted right, only 12 characters will show
    ele.value =  (new_number.length > 12) ? new_number.substring(12,0) : new_number;
    
    // restore cursor selection,
    // prevent it from going to the end
    // UNLESS
    // cursor was at the end AND a dash was added
    // document.getElementById('msg').innerHTML='<p>Selection is: ' + selection_end + ' and length is: ' + new_number.length + '</p>';
    
    if (new_number.slice(-1) === '-' && restore === false
        && (new_number.length === 8 && selection_end === 7)
            || (new_number.length === 4 && selection_end === 3)) {
        selection_start = new_number.length;
        selection_end = new_number.length;
    }
    else if (restore === 'revert') {
      selection_start--;
      selection_end--;
    }
    ele.setSelectionRange(selection_start, selection_end);
  
  }
    
 phone_number_check(field,e) {
    let keyCode = e.keyCode;
    if (e.keyCode >= 96 && e.keyCode <= 105) {
      // Numpad keys
      keyCode = e.keyCode - 48;
    }
    console.log(keyCode);
    var key_code = keyCode,
        key_string = String.fromCharCode(key_code),
        press_delete = false,
        dash_key = 189,
        delete_key = [8,46],
        direction_key = [33,34,35,36,37,38,39,40],
        selection_end = field.selectionEnd;
    
    // delete key was pressed
    if (delete_key.indexOf(key_code) > -1) {
      press_delete = true;
    }
    
    // only force formatting is a number or delete key was pressed
    if (key_string.match(/^\d+$/) || press_delete) {
      this.phone_formatting(field,press_delete);
    }
    // do nothing for direction keys, keep their default actions
    else if(direction_key.indexOf(key_code) > -1) {
      // do nothing
    }
    else if(dash_key === key_code) {
      if (selection_end === field.value.length) {
        field.value = field.value.slice(0,-1)
      }
      else {
        field.value = field.value.substring(0,(selection_end - 1)) + field.value.substr(selection_end)
        field.selectionEnd = selection_end - 1;
      }
    }
    // all other non numerical key presses, remove their value
    else {
      e.preventDefault();
  //    field.value = field.value.replace(/[^0-9\-]/g,'')
      this.phone_formatting(field,'revert');
    }
  
  }
  
 hinValidation(healthNumber){
    // Make sure the health card passes mod 11
        let mod11 = (code) => {
            if (code.length !== 10) {
                return false;
            }

            let value = code.split('').map((char) => {
                return Number(char);
            });

            if (value[0] !== 9) {
                return false;
            }

            let weights = [0, 2, 4, 8, 5, 10, 9, 7, 3, 0];
            // let reduced = 0;
            let result = value.reduce((reduced, item, index) => {
                return reduced + (item * weights[index]);
            }) - value[0];

            let checkDigit = 11 - (result - (((result / 11) >> 0) * 11));
            return checkDigit === value[9];
        };

        // Only verify the health number if there is one
        if (healthNumber.length > 0 && !mod11(healthNumber)) {
            // bc healthcard number is not valid
            return false;
        }

        return true;

  }

  async sendAndSaveAgreement(){
    let url = `${config.service}/services/agreement/save`
    let configAxios = {
      headers:{
        "Content-Type": "application/json"
      }
    };
    try{
      let res = await axios.post(url,null,configAxios);
      return res.data;

    }catch(err){
      // Sentry.captureException(err);
      throw err;
    }

  }
 
  // private method
  _agentHas(keyword) {
    return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
  }
  
  //https://medium.com/weekly-webtips/here-is-how-you-detect-the-browser-in-use-in-both-javascript-and-css-bcb5a6458379

  async manualPasswordReset(data){
    try{
      let configAxios = {
        headers: {
          "Content-Type": "application/json"
        },
      }

      let body = {
          email: data.email,
          password: data.password, 
          currentPassword: data.currentPassword,
          manualReset:true
      }
      let res = await axios.post(`${config.service}/login/reset`,body,configAxios);
      
      return res.data;

    }catch(err){
        throw err;
    }
  }

  async getUserPharmacyStatus(){
    try{
      let result = await axios.get(`${config.service}/pharmacy/me`);
      if(result.data.pharmacy){
        return true;
      }
      return false;

    }catch(err){
      throw err;
    }
  }

  async addPreferredPharmacy(data){
    const configAxios = {
      headers:{
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store, must-revalidate"
      }
    };
    let res = await axios.post(`${config.service}/pharmacy/addPreferredPharmacy`,data,configAxios);
  }


  async getCurrentAnouncement(){
    let res = await axios.get(`${config.service}/services2/anouncement`);

    return res.data.value;
  }
  



}
export default new Util();