import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { alertAction } from '../actions';
import { BadgeItem } from '../components/BadgeItem';
import { drugService } from '../services';
import { errorHandling } from '../_helpers';


class OtherAllergy extends Component {
    constructor(props) {
        super(props);

        // init
        this.state = {
            searchKey: '',
            searchList: [],
            list: props.value
        };
    }

    handleChange = (event) => {
        this.setState({
            searchKey: event.target.value
        });
    }

    handleSearch = async (event) => {
        const searchKey = event.target.value;

        this.setState({
            searchList: []
        });

        if (searchKey !== "" && searchKey.length > 3) {
            try {
                let [res, res2] = await drugService.getByNameOrIngredient(searchKey);

                res.data = res.data.concat(res2.data);
                if (res.data.length > 0) {
                    this.setState({
                        searchList: res.data,
                    });
                }
            } catch (error) {
                if (!drugService.isCancel(error)) {
                    errorHandling(
                        "OtherAllergy.handleSearch",
                        error,
                        this.props.dispatch
                    );
                }
            }
        }
    }

    handleSelect = (item) => {
        const list = this.state.list;

        // don't add duplicated item to the list
        const exist = list.find(o => o.name === item.name);

        if (exist === undefined) {
            this.addItem(item.name, item._id);
        } else {
            this.props.dispatch(alertAction.warning('Allergy is already existed!'));
        }
    }

    handleAdd = (event) => {
        event.preventDefault();

        const { searchKey } = this.state;

        if (searchKey !== '') {   
            this.addItem(searchKey);
        } else {
            this.props.dispatch(alertAction.error('Other allergy is required.'));
        }
    }

    addItem = (name, _id) => {
        const item = { _id, name };
        const newList = [...this.state.list, item];             // es6 spread operator

        this.setState({
            searchKey: '',
            searchList: [],
            list: newList
        });

        // match the event.target structure
        this.props.onChange({
            target: {
                name: this.props.name,
                value: newList
            }
        });
    }

    removeItem = (index) => {
        const list = this.state.list;

        list.splice(index, 1);

        this.setState({
            list
        });

        // match the event.target structure
        this.props.onChange({
            target: {
                name: this.props.name,
                value: list
            }
        });
    }

    renderInput() {
        return (
            <>
                <input type="text" className="form-control col-md-6" placeholder="Please specify to which medication you have an allergy"
                    value={this.state.searchKey} onChange={this.handleChange} onKeyUp={this.handleSearch} />
                <button className="btn btn-outline-danger" onClick={this.handleAdd}>
                    Save
                </button>
            </>
        );
    }

    renderSearchList() {
        if (this.state.searchList.length > 0) {
            return (
                <ul>
                    {
                        this.state.searchList.map((item, index) => {
                            return (
                                <li key={index} onClick={() => this.handleSelect(item)}>
                                    {item.name}
                                </li>
                            );
                        })
                    }
                </ul>
            );
        } else {
            return '';
        }
    }

    renderList() {
        const { list } = this.state;

        if (list.length > 0) {
            return (
                <div className="list">
                    {list.map((item, index) => {
                        return (
                            <BadgeItem key={index} item={item.name} onClick={() => this.removeItem(index)}/>
                        );
                    })}
                </div>
            );
        }

        return '';
    }

    render() {
        return (
            <div id="other-allergy">
                {this.renderInput()}
                {this.renderSearchList()}
                {this.renderList()}
            </div>
        );
    }
}


OtherAllergy.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.array
};


const temp = connect()(OtherAllergy);

export { temp as OtherAllergy };