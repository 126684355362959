import { 
    GET_ALL_PHARMACY,
    USER_LOADED
 } from './type';
import axios from 'axios';
import config from '../config';
import {setToLoaded,setToLoading} from './loader';

const configAxios = {
    headers:{
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store, must-revalidate"
    }
};

export const getAllPharmacy = () => async dispatch => {

    try{
        dispatch(setToLoading())
        let res = await axios.get(`${config.service}/pharmacy/all`);
        dispatch({   
            type:GET_ALL_PHARMACY, 
            payload:res.data
        })
        dispatch(setToLoaded())
        // console.log(res.data);
    }catch(e){
        dispatch(setToLoaded())
    }

}

export const addPreferredPharmacy = (data) => async dispatch => {
    try{
        dispatch(setToLoading())
        let res = await axios.post(`${config.service}/pharmacy/addPreferredPharmacy`,data,configAxios);
        
        if(res.data.success){
            dispatch({
                type:USER_LOADED,
                payload:res.data.extras.payload
            })
        }

        dispatch(setToLoaded())
    }catch(err){
        dispatch(setToLoaded())
    }
}