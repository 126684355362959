import React from 'react';
import TermsPrivacyFee from './TermsPrivacyFee';

const FooterLoggedIn = (props) => {


    if(location.pathname.includes('/medical/records')){
        return null;
    }


    return (
        <div id="footer-loggedin">
            <TermsPrivacyFee />
        </div>
    )

}

export default FooterLoggedIn