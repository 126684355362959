import i18next from 'i18next';

import { 
    SET_LANG
} from './type';


export const setLang = (lang = "en") => dispath => {
    i18next.changeLanguage(lang)
    dispath({
        type: SET_LANG,
        payload: lang
    })
}

export const toggleLang = () => (dispath, getState) => {
    let lang = getState().lang.lang;
    lang = (lang == "en" || typeof lang == "undefined") ? "cn" : "en";
    dispath(setLang(lang))
}