
import axios from 'axios';
import config from '../config';


export const sickNoteService = {
    getByPerson,
    getById,
    save,
    charge,
    saveSickNote,
    validateSickNote
};

function getByPerson() {

    const url = `${config.service}/services/medical-info`;
    let token = localStorage.getItem('accessToken');
    axios.defaults.headers.common['x-access-token'] = token;
    return axios.get(url);
}

function getById(id) {
    const url = `${config.askmedApiHost}/medicalinfo/${id}`;
    axios.defaults.headers.common['x-access-token'] = config.askmedSecret;

    return axios.get(url);
}


function save(medicalInfoObj) {
    const url = `${config.askmedApiHost}/medicalinfo/`;

    axios.defaults.headers.common['x-access-token'] = config.askmedSecret;

    return axios.post(url, medicalInfoObj);
}

function charge(data) {
    const url = `${config.service}/sick-note/charge`;
    axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('accessToken');
    return axios.post(url, data);
}
function saveSickNote(sickNoteObj) {
    const url = `${config.service}/services/sicknote`;
    let token = localStorage.getItem('accessToken');
    axios.defaults.headers.common['x-access-token'] = token;
    const body = {
        sickNoteObj
    }
    

    return axios.post(url, body);
}

function validateSickNote(sickNoteObj) {
    const url = `${config.service}/services/sicknote/validate`;
    let token = localStorage.getItem('accessToken');
    axios.defaults.headers.common['x-access-token'] = token;
    const body = {
        sickNoteObj
    }
    return axios.post(url, body);
}
