import React,{useEffect} from 'react';
import {connect} from 'react-redux';
import {ReplyLoginWithToken} from '../actions/auth';
import Spinner from '../components/layout/SpinningControls';

class MessageAuth extends React.Component {

    componentDidMount() {
        const { match: { params },ReplyLoginWithToken } = this.props;
    
       
        ReplyLoginWithToken(params.token);
    }

    render() {
        return <Spinner/>
    }
}

const mapDispatchToProps = {
    ReplyLoginWithToken
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})
export default connect(mapStateToProps, mapDispatchToProps)(MessageAuth);