import { medicalInfoService } from '../services';
import { medicalInfoConstant } from '../Constants';
import { alertAction } from './alert.action';
import { getPersonId, errorHandling, isEmptyObj } from '../_helpers';
import {setToLoading,setToLoaded} from './loader'
import {setAlert}from './alert';


export const medicalInfoAction = {
    getByPerson,
    getById,
    change,
    save
};

function getByPerson() {
    return dispatch => {
        dispatch(setToLoading());
        medicalInfoService.getByPerson()
            .then(
                res => {
                    const array = res.data.medicalInfo;
                    const askmedUser = res.data.user;
                    const medicalInfoObj = getLatestMedicalInfo(array);
                    const pastMedicalInfoObjs = getPastMedicalInfo(array);
                    const actionObj = init(medicalInfoObj, askmedUser);

                    dispatch({
                        type: medicalInfoConstant.GET_BY_PERSON,
                        ...actionObj,
                        pastMedicalInfoObjs,
                        askmedUser,
                    });
                    dispatch(setToLoaded());
                },
                error => {
                    console.log('error',error)
                    dispatch(setToLoaded());
                    dispatch(setAlert('Server error', 'danger',5000,'alert-fixed'));
                    // errorHandling('medicalInfo.action.getByPerson', error, dispatch);
                }
            );
    };
}

function getById(id) {
    return dispatch => {
        medicalInfoService.getById(id)
            .then(
                res => {
                    const actionObj = init(res.data);

                    dispatch({
                        type: medicalInfoConstant.GET_BY_ID,
                        ...actionObj
                    });
                },
                error => {
                    errorHandling('medicalInfo.action.getById', error, dispatch);
                }
            );
    };
}

function init(medicalInfoObj, user) {
    const noMedicalInfo = isEmptyObj(medicalInfoObj);

    if (noMedicalInfo) {
        medicalInfoObj = {
            Person: user.Person._id,
            Sex: '',
            MedicalConditions: [],
            Surgeries: [],
            AllergyToMedication: [],
            NoMedicalCondition: false,
            NoSurgery: false,
            NoAllergy: false,
            MedicationsCurrentlyTaken: [],
            supplements: '',
            otherItems: '',
            AlcoholHistory: '',
            SmokingHistory: '',
            defaultPharmacy: null,
            otherMedicalConditions: null,
            otherSurgery: null,
            otherAllergy: null,
          
        };
    } else {
        medicalInfoObj.NoMedicalCondition = medicalInfoObj.MedicalConditions.length === 0 && medicalInfoObj.otherMedicalConditions === null;
        medicalInfoObj.NoSurgery = medicalInfoObj.Surgeries.length === 0 && medicalInfoObj.otherSurgery === null;
        medicalInfoObj.NoAllergy = medicalInfoObj.AllergyToMedication.length === 0 && medicalInfoObj.otherAllergy === null;
    }

    return {
        medicalInfoObj,
        noMedicalInfo
    };
}

function getLatestMedicalInfo(medicalInfoList) {
    let medicalInfoObj = {};

    // get the latest medicalInfo object
    if (medicalInfoList !== undefined && medicalInfoList.length > 0) {
        // medicalInfoObj = [...medicalInfoList].pop();
        medicalInfoObj = [...medicalInfoList][0];
    }

    return medicalInfoObj;
}

function getPastMedicalInfo(medicalInfoList) {
    let pastMedicalInfo = [];

    if (medicalInfoList !== undefined && medicalInfoList.length > 1) {
        pastMedicalInfo = [...medicalInfoList].slice(1);   
    }
    return pastMedicalInfo;
}

function change(medicalInfoObj) {
    return {
        type: medicalInfoConstant.CHANGE,
        medicalInfoObj: medicalInfoObj
    };
}

function save(medicalInfoObj, callback = undefined) {
    return dispatch => {
        dispatch(setToLoading())
        medicalInfoService.save(medicalInfoObj)
            .then(
                res => {
                    const actionObj = init(res.data);
                    
                    dispatch({
                        type: medicalInfoConstant.SAVE,
                        ...actionObj
                    });
                    if (callback) {
                        callback();
                    }
                    dispatch(setToLoaded())
                    dispatch(setAlert('Medical information updated successfully.','success',5000,'alert-fixed'))
                    // dispatch(alertAction.success('Medical information updated successfully.'));
                },
                error => {
                    dispatch(setToLoaded())
                    dispatch(setAlert('Error occured','danger',5000,'alert-fixed'))
                    // errorHandling('manage-data.action.buildDrugs', error, dispatch);
                }
            );
    };
}