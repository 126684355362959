import React from 'react';
import Modal from 'react-bootstrap/Modal';

import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

import config from '../../config'

const FeeScheduleModal = (props) => {

    return (
        <Modal
            show={props.show}
            onHide={e => props.footerClick(e, false)}
            size="lg"
            centered
        >
            <ModalHeader className="centered display-block">
                <ModalTitle id="terms-title">
                    {config.homepageUrl}<br />
                    FEE SCHEDULE
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
             <Table>
                 <thead>
                 </thead>
                 <tbody>
                     <tr>
                         <td rowspan='3' className="border-top-0">
                             Booking an Appointment with a Provider at the
                             <br /> 
                             {config.clinicName}
                         </td>
                         <td className="border-top-0">Valid British Columbia Services Card</td>
                         <td className="border-top-0">Free</td>
                     </tr>
                     <tr>
                         <td>Valid extra-provincial health card (Alberta, Ontario, etc. except Quebec)</td>
                         <td valign="middle">$5.00</td>
                     </tr>
                     <tr>
                         <td>Not Canadian resident<br />
Invalid British Columbia Services Card<br />
Invalid extra-provincial health card<br />
Quebec Health Insurance Card
</td>
                         <td valign="middle">
$250.00
</td>
                     </tr>
                     {/* <tr>
                         
                         <td colspan='2'>
                         Driver Medical Exam
</td>
                         <td valign="middle">$140.00 (Online discount, regular price $200.00)
</td>
                     </tr> */}
                     <tr>
                     
                         <td colspan='2'>
                         Certificate for time off work or school (Sick Note)
</td>
                         <td valign="middle">$50.00
</td>
                     </tr>
                     <tr>
                     
                     <td colspan='2'>
                     Insurance and other paperwork per page (telemedicine) 
</td>
                     <td valign="middle">$100.00
</td>
                 </tr>
                 </tbody>
             </Table>

            </ModalBody>
            <ModalFooter>
                <Button name="fee-schedule" variant="secondary" onClick={e => props.footerClick(e, false)}>Close</Button>
                {/* <Button variant="primary" onClick={props.confirm}>{props.confirmText}</Button> */}
            </ModalFooter>
        </Modal>

    )

}

export default FeeScheduleModal;