import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import Modal from '../layout/Modal';
import YearDropDown from '../layout/YearDropDown';
import MonthDropDown from '../layout/MonthDropDown';
import DayDropDown from '../layout/DayDropDown';

import {Container, Col, Row} from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';


import {loadMedicalRecordsWithToken,getMedicalRecordsWithDob} from '../../actions/medicalRecord';
import config from '../../config';


class MedicalRecordsDownload extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            token: null,
            showModal:false,
            dobYear:"",
            dobMonth:"",
            dobDay:""
        }
        
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        // decoded token
        this.setState({ token: params.token});
        this.props.loadMedicalRecordsWithToken(params);

    }

    componentDidUpdate(prevProps) {

        if(prevProps.isDownloadTokenValid !==this.props.isDownloadTokenValid && this.props.isDownloadTokenValid) { 
            this.setState({
                showModal:true
            })
        }
    }

    onChange(e) {
        
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    confirmDateOfBirth(){
        // confirm date of birth;
        const {dobYear,dobMonth,dobDay} = this.state;

        if(!dobYear){
            alert('Invalid date of birth, Year cannot be empty.')
            return;
        }

        if(!dobMonth){
            alert('Invalid date of birth, Year cannot be empty.')
            return;
        }

        if(!dobDay){
            alert('Invalid date of birth, Year cannot be empty.')
            return;
        }

        const data = {
            dobYear,
            dobMonth,
            dobDay,
            token:this.state.token
        }

        // validate against the server record
        this.props.getMedicalRecordsWithDob(data);
    }

    renderFileList() {

        if(this.props.fileList.length ==0) return null;

        return <>
            <hr/>

            <p>Please ensure to safely store the downloaded records as you will only be able to access this link once. If you have any difficulty please contact us at info@southvanmed.com</p>

            <ListGroup>
                {this.props.fileList.map((file,index)=>{

                if(file.isDownloaded){
                    return <ListGroup.Item key={index}> 
                    <span className="file-downloaded-text">Already downloaded</span>&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="file-downloaded-link" href={`#`}>{file.fileName}&nbsp;&nbsp;<i className="fa-solid fa-download"></i></span> </ListGroup.Item>
                    
                }

                    return <ListGroup.Item key={index}>
                        <span className="file-download-text">Available </span>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a className="file-download-link" href={`${config.service}/services2/medical/record/download/${file.id}?token=${this.state.token}`} target="_blank">{file.fileName}&nbsp;&nbsp;<i className="fa-solid fa-download"></i></a> 
                </ListGroup.Item>
                })}
            </ListGroup>
        </>
    }



    renderMessage() {


        return <>
            <Row>
                <Col sm={12} md={6}>Please enter the patient's date of birth in order to download the medical records</Col>
                <Col sm={12} md={2}>
                    <YearDropDown 
                        classStyle="full-width centered"
                        name="dobYear"
                        onChange={this.onChange.bind(this)}
                        value={this.state.dobYear}
                        disabled={this.props.fileList.length > 0}
                    /> 
                </Col>
                <Col sm={12} md={2}>
                    <MonthDropDown 
                        classStyle="full-width centered"
                        name="dobMonth"
                        onChange={this.onChange.bind(this)}
                        value={this.state.dobMonth}
                        disabled={this.props.fileList.length > 0}
                    />
                </Col> 
                <Col sm={12} md={2}>
                    <DayDropDown 
                        classStyle="full-width centered"
                        name="dobDay"
                        onChange={this.onChange.bind(this)}
                        value={this.state.dobDay}
                        disabled={this.props.fileList.length > 0}
                    />
                </Col>
            </Row>        

            {this.renderFileList()}
        </>

    }

    render() {
        // if(!this.state.token) return <Redirect to='/' />

        const {isLoading,isDownloadTokenValid} = this.props;

        if(!isLoading && !isDownloadTokenValid) return <Redirect to='/' />
        

        return <>
            <div id="medical-record-container">
                <Modal
                    header="Medical Records download"
                    show={this.state.showModal}
                    message={this.renderMessage()}
                    setShow = {()=>{}}
                    confirm={this.confirmDateOfBirth.bind(this)}
                    confirmText="Confirm"
                    cancelText='Close'
                    cancelFunc={()=>{window.close()}}
                    hideCloseButton={true}
                    closeButton={false}
                    keyboard={false}
                    hideConfirmButton={this.props.fileList.length > 0}
                />
            </div>
        </>
    }
}

const mapDispatchToProps = {
    loadMedicalRecordsWithToken,
    getMedicalRecordsWithDob
}
const mapStateToProps = state => ({
    isLoading:state.medicalRecord.isLoading,
    isDownloadTokenValid:state.medicalRecord.isDownloadTokenValid,
    fileList:state.medicalRecord.fileList
    
})
export default connect(mapStateToProps, mapDispatchToProps)(MedicalRecordsDownload);