import React from 'react';
import { scroller } from 'react-scroll';
import vaccinesFile from '../assets/files/Vaccines_south_vancouver_clinic.pdf'
import { Helmet } from 'react-helmet';


class Policies extends React.Component {



    componentDidMount() {
        this._scrollToTop();
    }

    _scrollToTop(){
        scroller.scrollTo("policies", {
            duration: 800,
            smooth: true,
            offset: -100
        });
    }


    renderPolicy() {
        return (
            <>
                <section id="policies" className="py-2">
                    <div className="container">
                        <h1 className="center p-1">
                            Clinic <span className="keywords">policies</span>
                        </h1>
                        <div className="bottom-line"></div>
                        <div className="policy-container">
                            <article className="card">
                                <div>
                                    <h3 className="mb-1">Cancellations and Appointment No Shows</h3>
                                    <div className="bottom-line"></div>

                                    <p>
                                        We require 24 hours notice to cancel an appointment with a general practitioner. Our general surgeon requires five (5) business days’ notice for cancellations. There are many other patients waiting for appointments and failure to cancel within specified timeframes will result in a <span class="important-text">missed appointment charge of (a) $50.00 for missed appointments with a general practitioner, or (b) $100.00 for missed appointments with a specialist.</span>
                                    </p>
                                </div>
                            </article>

                            <article className="card">
                                <div>
                                    <h3 className="mb-1">
                                        Specialist Referrals and Hospital Investigations
              </h3>
                                    <div className="bottom-line"></div>
                                    <p>
                                        We will do our best to organize specialist referrals and hospital investigations in a timely manner. <span class="important-text">However, wait times are often long.</span> Should you not receive a follow-up from us regarding specialist referrals or hospital investigations within two (2) months (or earlier for urgent matters), please inquire at our clinic about the status of your referral.
                                    </p>

                                    <br />
                                </div>
                            </article>

                            <article className="card">
                                <div>
                                    <h3 className="mb-1">Investigation Results</h3>
                                    <div className="bottom-line"></div>
                                    <p>Our clinic policy is to <span class="important-text">not</span> provide lab results over the telephone. Only our doctors can interpret lab results and the staff will <span class="important-text">not</span> comment when asked. Please understand that it can take up to ten (10) days or longer for our office to receive results back from various labs or hospitals, and we are not responsible for any such delays.</p>
                                    <br />
                                    <p>Please always make an appointment with a physician at our clinic after you undergo a hospital investigation in order to discuss results. We will do our best to contact you to book an appointment if your investigation results are abnormal. We will attempt to call you and, if we are unable to reach you, or a family member, or to leave a voicemail, we shall send you a letter in the mail. From time to time, we may contact you to discuss relevant normal results. However, note that no follow up system is 100% accurate or failsafe. Therefore, we suggest that you to follow up with a doctor at our clinic after every hospital investigation that you undergo.</p>
                                    <br />
                                    <p><span class="font-it f-1">Please note:</span> At times we may need to leave messages on your voicemail (such as the time and place of a specialist referral or a message that lab tests are normal). We will, however, leave only very general information on voicemail. Hence, <span class="important-text">please provide us with the most secure phone number(s) that you have.</span> If one of your phone numbers cannot or should not be used to leave voicemail messages due to confidentiality concerns, please ask us to remove this phone number from your chart.</p>
                                    <br />

                                </div>
                            </article>

                            <article className="card">
                                <div>
                                    <h3 className="mb-1">Controlled Prescriptions</h3>
                                    <div className="bottom-line"></div>
                                    <p>Our doctors <span class="important-text"> do not </span> prescribe oxycodone (Percocet), buprenorphine (Suboxone) or methadone.</p>
                                    <br />
           
                                </div>
                            </article>

                            <article className="card">
                                <div>
                                    <h3 className="mb-1">Transferring Records to our Clinic</h3>
                                    <div className="bottom-line"></div>
                                    <p>When transferring records to our clinic, always provide us with photocopies of records and <span class="important-text"> not the original documents.</span> We shred all records that are scanned into our electronic medical record database. Hence, the originals should remain in your or your former doctor's possession.</p>
                                    <br />
                                </div>
                            </article>
                        </div>
                    </div>
                </section>
            </>
        )
    }

    renderParterLink() {
        return (
            <>
                <section id="links">
                    <h1 className="p-1 center">Associate <span className="keywords">Links</span></h1>
                    <div className="bottom-line"></div>

                    <div className="links-container-top">

                        <div>
                            <a href="http://greigassociates.com/">
                                <img className="lazyload" data-src="img/policy-links/policy_greig-min.jpg" alt="policy_greig" />
                            </a>
                        </div>

                        <div>
                            <a href="http://www.health.gov.bc.ca/msp/">
                                <img className="lazyload" data-src="img/policy-links/policy_msp-min.jpg" alt="policy_msp" />
                            </a>
                        </div>

                        <div>
                            <a href="http://www.vch.ca/402/7678/?site_id=147">
                                <img className="lazyload" data-src="img/policy-links/policy_schc-min.jpg" alt="policy_schc" />
                            </a>
                        </div>

                        <div>
                            <a href="http://www.smpbc.ca/default.htm">
                                <img className="lazyload" data-src="img/policy-links/policy_smpBC-min.jpg" alt="policy_smpBC" />
                            </a>
                        </div>
                    </div>

                    <div className="links-container-bottom">
                        <div>
                            <a href="http://www.icbc.com/">
                                <img className="lazyload" data-src="img/policy-links/policy_icbc-min.jpg" alt="policy_icbc" />
                            </a>
                        </div>

                        <div>
                            <a href="http://www.worksafebc.com/">
                                <img className="lazyload" data-src="img/policy-links/policy_worksafebc-min.jpg"
                                    alt="policy_worksafebc" />
                            </a>
                        </div>
                        <div>
                            <a href="http://cmlhealthcare.com/">
                                <img className="lazyload" data-src="img/policy-links/policy_cml-min.jpg" alt="policy_cml" />
                            </a>
                        </div>

                        <div>
                            <a href="http://www.lifelabs.com/Lifelabs_BC/">
                                <img className="lazyload" data-src="img/policy-links/policy_lifelabs1-min.jpg"
                                    alt="policy_lifelabs1" />
                            </a>
                        </div>
                    </div>
                </section>
            </>
        )
    }

    render() {
        return (
            <>  
                <Helmet>
                    <title>Clinic Policies | Richmond In-Store Medical Clinic | Vancouver doctor</title>
                    <link rel="canonical" href="https://instoremed.com/policies" />
                    <meta name="description" content="The clinic policies for Richmond In-Store Medical Clinic"/>
                    <meta name="keywords" content="Cancellations Appointment, No Shows, Specialist Referrals, Hospital Investigations,Investigation Results,Controlled Prescriptions,Routine Childhood Vaccinations,Transferring Records, vaccinations" />

                </Helmet>

                {this.renderPolicy()}
                {this.renderParterLink()}
            </>
        )
    }
}

export default Policies;