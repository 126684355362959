/* redux actions for loader */
import {LOADER_LOADED,LOADER_LOADING} from './type';


// dispatch actions


export const setToLoading = () => dispath => {
    dispath({
        type:LOADER_LOADING
    })
}   

export const setToLoaded = () => dispath => {
    dispath({
        type:LOADER_LOADED
    })
}   