import React from 'react';
import {Container,Row,Col,Tabs,Tab} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
 
//components
import Modal from './layout/Modal';
import Alert from './layout/Alert';
import AlertDialog from './layout/AlertDialog';
import CountryList from './layout/CountryList';
import PharmacySelect from './layout/PharmacySelect';

//Actions
import {setAlert} from '../actions/alert';
import {loadUser,setUser,setShouldUpdate} from '../actions/auth';
import {setToLoaded,setToLoading} from '../actions/loader';
// services

import Util from '../services/Utility';
import InputVerifier from "../services/InputValidationService";



// constants
// import config from '../config';
const validProvinces = ["British Columbia","Alberta","Manitoba","Nunavut","New Brunswick","Newfoundland and Labrador","Northwest Territories","Ontario","Prince Edward Island","Quebec","Saskatchewan","Yukon","Other"];

class MyAccount extends React.Component {
    constructor(props) {
        super(props);
        this._mounted = false;
        // this.hasFamilyDoc = config.hasFamilyDoc || "1998";
        // this.noFamilyDoc = config.noFamilyDoc || "1999";

        this.state = {
            validationErrors: [],
            errorMessage: "",
            allowEdit: false,
            isValidProvinceFound:true,
            redirectToDashboard:false,
            pharmacyList:this.props.pharmacy.list,
            items:{
              firstName: (this.props.user && this.props.user.firstName) ? this.props.user.firstName : "",
              lastName: (this.props.user && this.props.user.lastName) ? this.props.user.lastName : "",
              address: (this.props.user && this.props.user.address) ? this.props.user.address : "",
              city: (this.props.user && this.props.user.city) ? this.props.user.city : "",
              province: (this.props.user && this.props.user.province) ? this.props.user.province : "",
      
              postal: (this.props.user && this.props.user.postal) ? this.props.user.postal : "",
              phone: (this.props.user && this.props.user.phone) ? this.props.user.phone : "",
              mobilePhone: (this.props.user && this.props.user.mobilePhone ) ? this.props.user.mobilePhone : "",
              internationalPhone: (this.props.user && this.props.user.internationalPhone) ? this.props.user.internationalPhone: "",
              birthYear: (this.props.user && this.props.user.birthday.year) ? this.props.user.birthday.year : "",
              birthMonth: (this.props.user && this.props.user.birthday.month) ? this.props.user.birthday.month : "",
              birthDay: (this.props.user && this.props.user.birthday.day) ? this.props.user.birthday.day : "",
              healthCardNumber:(this.props.user && this.props.user.careCard.number) ? this.props.user.careCard.number : "",
              healthCardProvince:(this.props.user && this.props.user.careCard.province) ? this.props.user.careCard.province : "",
              sex: (this.props.user && this.props.user.sex) ? this.props.user.sex : "",
              email:(this.props.user && this.props.user.email)? this.props.user.email : "",
              country:(this.props.user && this.props.user.country) ? this.props.user.country : "",
              preferredPharmacy: (this.props.user && this.props.user.preferredPharmacy) ? this.props.user.preferredPharmacy : "",
              familyDoc:"",
              hasFamilyDoc:false
            },
            
            shouldUpdate:this.props.user ? this.props.user.shouldUpdate : false,
            showFamilyDocModal:this.props.user ? this.props.user.shouldUpdate : false,
            isConfirmInfoModalShown:false,
            passwordReset1:'',
            passwordReset2:'',
            currentPassword:''
            // isConfirmInfoModalShown:this.props.user ? this.props.user.shouldUpdate : false
          };
    }

    componentDidMount() {
        this.props.setToLoaded()
        this._mounted = true;
        this._validateProvince();
         this._modalText = <>
            {/* <p>Please confirm that the information we have on file is correct. Double check your address. Add new phone numbers and delete phone numbers that are no longer in service.</p>
            <hr /> */}
    
            <Row>
              <Col sm={12} md={4}>
                <b>First Name:</b> {this.state.items.firstName}
              </Col>
              <Col sm={12} md={4}>
                <b>Last Name:</b> {this.state.items.lastName}
              </Col>
              <Col sm={12} md={4}>
              <b>Gender:</b> {this.state.items.sex}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={12} md={6}>
                <b>Address:</b> {this.state.items.address}
              </Col>
              <Col sm={12} md={6}>
                <b>City:</b> {this.state.items.city}
              </Col>
            </Row>
              <hr />
              <Row>
              <Col sm={12} md={6}>
                <b>Province:</b> {this.state.items.province}
              </Col>
              <Col sm={12} md={6}>
                <b>Postal:</b> {this.state.items.postal}
            </Col>
    
            </Row>
            <hr />
    
            <Row>
            <Col sm={12} md={4}>
              <b>Mobile Phone :</b> {this.state.items.mobilePhone}
              </Col>
              
              <Col sm={12} md={4}>
                <b>Home Phone:</b> {this.state.items.phone}
              </Col>
              <Col sm={12} md={4}>
                <b>International Phone:</b> {this.state.items.internationalPhone?this.state.items.internationalPhone:"N/A"}
              </Col>
            </Row>
            {/* <hr /> */}
            {/* <Row>
            <Col sm={12} md={6}>
              <b>HealthCard Number :</b> {this.state.items.healthCardNumber}
              </Col>
              
              <Col sm={12} md={6}>
                <b>HealthCard Province:</b> {this.state.items.healthCardProvince}
              </Col>
            </Row> */}
            </>
            // this._setDefaultPreferredPharmacy()

    }

    componentWillUnmount(){
        this._mounted = false;
    }
    
    componentDidUpdate(prevProps,prevState){
        if(this._mounted){
          
          if(prevProps.user !== this.props.user){
            console.log('compounentDidUpdate','user exists');
            this._onUserDidChange(prevProps);
            // this._setDefaultPreferredPharmacy()
          }

          if(prevProps.pharmacy.list !== this.props.pharmacy.list){
            this.setState({pharmacyList: this.props.pharmacy.list})
          }
        }
        
    }

    _validateProvince(){
        if(!validProvinces.includes(this.state.items.province)){
          this.setState({
            isValidProvinceFound:false
          })
          return;
        }
        this.setState({
          isValidProvinceFound:true
        })
      }
    


    _onUserDidChange(prevProps) {
        //    console.log('user did change')
            // this.props.setToLoading();
            if(this.state.shouldUpdate){
              let redirectToDashboard = (prevProps.user.shouldUpdate && !this.props.shouldUpdate && this._shouldRedirect) ? true : false;
              if(redirectToDashboard){
                this.setState({
                  redirectToDashboard
                })
                return;
              }
            return;
          }


          this.setState({
            items:{
              ...this.state.items,
              firstName: this.props.user.firstName || "",
              lastName: this.props.user.lastName || "",
              address: this.props.user.address || "",
              city: this.props.user.city || "",
              province: this.props.user.province || "",
              postal: this.props.user.postal || "",
              phone: this.props.user.phone || "",
              mobilePhone: this.props.user.mobilePhone || "",
              internationalPhone: this.props.user.internationalPhone || "",
              birthYear: this.props.user.birthday.year || "",
              birthMonth: this.props.user.birthday.month || "",
              birthDay: this.props.user.birthday.day || "",
              healthCardProvince: this.props.user.careCard.province || "",
              healthCardNumber: this.props.user.careCard.number || "",
              sex: String(this.props.user.sex) || "",
              email:this.props.user.email || "",
              country:this.props.user.country||"",
              shouldUpdate:this.props.user.shouldUpdate || false,
              isConfirmInfoModalShown:this.props.user.shouldUpdate || false,
              preferredPharmacy:this.props.user.preferredPharmacy
            },
            isConfirmInfoModalShown:this.props.user.shouldUpdate || false,
            shouldUpdate:this.props.user.shouldUpdate || false
  
          },()=>{
            this.props.setToLoaded();
            this.props.setAlert(`User info updated successfully`,'success');
          });
          
          
      }
    
    closeAlert() {
        this.setState({
          errorMessage: ""
        });
    }

    validateForm() {

        // this.props.setToLoading();
        let errors = [];
        let excludeItem = ["healthCardNumber","healthCardProvince","mobilePhone","phone","internationalPhone","familyDoc"];
        let stateKeys = Object.keys(this.state.items);
        // console.log(this.state)
        // console.log(stateKeys)
        if(!this.state.isValidProvinceFound){
          excludeItem.push('province');
        }

        if(!this.props.user.preferredPharmacy){
          excludeItem.push('preferredPharmacy')
        }


        stateKeys.forEach(key => {
          if (!excludeItem.includes(key))
            InputVerifier.checkEmpty(this.state.items[key], key, errors);
        });
    
        if(errors.length>0){
          this.setState({ 
            validationErrors: errors,
            errorMessage: "Highlighted input field cannot be empty."
           });
          return false;
        }
    
        errors = Util.phoneEntryValidateMyAccount(this.state.items,errors);
    
        if(errors.length>0){
          this.setState({ 
            validationErrors: errors,
            errorMessage: "Either Mobile Phone, Home Phone or International Phone is required."
           });
          return false;
        }
    
        return true;
    
      }

      validate2(){
        let errors = [];
        if(!this.state.isValidProvinceFound){
          errors.push("province");
          // this.props.setToLoaded();
          this.setState({
            errorMessage: "Please choose a Province from the drop down menu",
            validationErrors:errors
          })
          return false;
        }
    
        if(this.state.items.mobilePhone){
          if(!Util.isNumber(this.state.items.mobilePhone) || this.state.items.mobilePhone.length!==10){
            errors.push("mobilePhone")
            // this.props.setToLoaded();
            this.setState({
              errorMessage: "Mobile phone is not valid,please correct your input and try again",
              validationErrors:errors
            })
            return false;
          }
    
        }
        if(this.state.items.phone){
          if(!Util.isNumber(this.state.items.phone) || this.state.items.phone.length!==10){
            errors.push("phone")
            // this.props.setToLoaded();
            this.setState({
              errorMessage: "Home phone is not valid,please correct your input and try again",
              validationErrors:errors
            })
            return false;
          }
        }
        if(this.state.items.internationalPhone){
          if(!Util.isNumber(this.state.items.internationalPhone)){
            errors.push("internationalPhone")
            // this.props.setToLoaded();
            this.setState({
              errorMessage: "International phone is not valid,please correct your input and try again",
              validationErrors:errors
            })
            return false;
          }
        }
        return true;
    
      }
    

    confirmInfoModal(){
        try{
       // change shouldUpdate to false
          this._shouldRedirect = true;
          this.setState({
            isConfirmInfoModalShown:false
          })

          let data = {
              shouldUpdate:false,
              hasFamilyDoc:this.state.items.hasFamilyDoc
          }
          this.props.setShouldUpdate(data);
    
        }catch(err){
          console.log(err)
        }
      }
    
      editInfoFromModal(){
        console.log("cancel modal clicked")
        this.setState({
          allowEdit:true,
          isConfirmInfoModalShown:false
        })
      }
    
      setShowConfirmInfoModal(bool){
        this.setState({
          isConfirmInfoModalShown:bool
        })
      }
    
      toggleFamilyDocModal(bool){
        this.setState({
          showFamilyDocModal:bool
        })
      }
    
      confirmFamilyDoc(){
        this.setState({
          items:{
            ...this.state.items,
            hasFamilyDoc:true
          }
        })
    
        this.toggleFamilyDocModal(false)
        this.setShowConfirmInfoModal(true)
      }
    
      cancelFamilyDoc(){
        this.setState({
          items:{
            ...this.state.items,
            hasFamilyDoc:false
          }
        })
    
        this.toggleFamilyDocModal(false)
        this.setShowConfirmInfoModal(true)
      }

      _getDateOfBirthVal(){
        // console.log(this.state.birthMonth)
        let mon =
        this.state.items.birthMonth.length === 1
          ? `0${this.state.items.birthMonth}`
          : `${this.state.items.birthMonth}`;
      let day =
        this.state.items.birthDay.length === 1
          ? `0${this.state.items.birthDay}`
          : `${this.state.items.birthDay}`;
      let dateOfBirthVal = `${this.state.items.birthYear}-${mon}-${day}`;
      return dateOfBirthVal;
      }

      toggleEdit(){
        //
        if(this.state.shouldUpdate){
          this.setShowConfirmInfoModal(true)
        }
    
        this.setState({
          allowEdit: !this.state.allowEdit
        });
      }

      _setDefaultPreferredPharmacy(){
        const {user} = this.props;
        const {pharmacyList} = this.state;
        if(!user) return;
        if(user.preferredPharmacy){
          let defaultIndex = 0;
          pharmacyList.every((ph,index)=>{
            if(ph.name===user.preferredPharmacy.name){
              defaultIndex = index;
              return false;
            }
            return true;
          })
          this.setState({
            items:{
              ...this.state.items,
            preferredPharmacyIndex:defaultIndex
            }
          })
        }
      }


 async updateData() {

    if (!this.validateForm()) {
      // console.log(this.state)
      // this.props.setToLoaded();
      return;
    }
    if(!this.validate2())return;

    this.setState({allowEdit:false});
    let data =  {
        firstName: this.state.items.firstName,
        lastName: this.state.items.lastName,
        address: this.state.items.address,
        city: this.state.items.city,
        province: this.state.items.province,
        postal: this.state.items.postal,
        phone: this.state.items.phone,
        mobile: this.state.items.mobilePhone,
        internationalPhone: this.state.items.internationalPhone,
        birthYear: this.state.items.birthYear,
        birthMonth: this.state.items.birthMonth,
        birthDay: this.state.items.birthDay,
        sex: this.state.items.sex,
        country:this.state.items.country,
        familyDoc: this.state.items.familyDoc,
        shouldUpdate:this.state.shouldUpdate,
        hasFamilyDoc:this.state.items.hasFamilyDoc,
        preferredPharmacy:this.state.items.preferredPharmacy
    };
    try{

      this.props.setUser(data);
      
        // setTimeout(()=>{
        //   this.setState({
        //     errorMessage:"User information updated successfully."
        //   })
        //   this.props.setToLoaded();
        // },800)
      
    }catch(err){
      // this.props.setToLoaded();
      this.setState({
        errorMessage: "Server error occured."
      });
    }
    
  }



      handleChange(event) {
        if(event.target.name==="mobilePhone"||event.target.name==="phone"||event.target.name==="internationalPhone"){
          let re = /^[0-9\b]+$/;
          if (event.target.value === '' || re.test(event.target.value)){
            this.setState({
              items:{
                ...this.state.items,
                [event.target.name]: event.target.value
              }
            });
          }
          return;
        }
        if(event.target.name==="province" && validProvinces.includes(event.target.value)){
          this.setState({
            isValidProvinceFound:true
          })
        }
        this.setState({
          items:{
            ...this.state.items,
            [event.target.name]: event.target.value 
          } 
        });
        // console.log(this.state)
      }


      handlePasswordChange(event) {
        this.setState({
          [event.target.name]: event.target.value 
        });
      }

      async resetPassword(){

        this.setState({
          validationErrors:[]
        });
        let errors = []

        if(!this.state.currentPassword){
          errors.push('currentPassword');
          this.setState({errorMessage:'Please enter the current password.',validationErrors:errors});
          return
        }

        if(this.state.currentPassword.length<8){
          errors.push('currentPassword');
          this.setState({errorMessage:'Your current password must be at least 8 characters.',validationErrors:errors});
          return
        }


        if(!this.state.passwordReset1){
          errors.push('passwordReset1');
          this.setState({errorMessage:'Please enter a password.',validationErrors:errors});
          return
        }
        if(!this.state.passwordReset2){
          errors.push('passwordReset2');
          this.setState({errorMessage:'Please confirm your password.',validationErrors:errors});
          return
        }

        if(this.state.passwordReset1.length < 8){
          errors.push('passwordReset1');
          this.setState({errorMessage:'The length of password must be greater than 8',validationErrors:errors});
          return
        }

        if(this.state.passwordReset1 !== this.state.passwordReset2){
          errors.push('passwordReset1');
          errors.push('passwordReset2');
          this.setState({errorMessage:'The passwords you entered does not match',validationErrors:errors});
          return
        }

        // this.props.setToLoading();
        let data = {
          email:this.props.user.email,
          password:this.state.passwordReset1,
          currentPassword:this.state.currentPassword
        }

        try {
          this.props.setToLoading();
          let result = await Util.manualPasswordReset(data);
          // console.log(result)
          this.props.setToLoaded();
          if(result.success) {
            this.setState({errorMessage:"Your password has been changed successfully."});
            return;
          }
          let msg = result.extras.message || 'Reset password failed.';
          this.setState({errorMessage:msg})

        }catch(e) {
          this.props.setToLoaded();
          this.setState({errorMessage:'Server error.'})
        }


      }

      renderPreferedPharmacy(){
        // console.log(this.state.items.preferredPharmacy)
        if(this.state.pharmacyList.length==0 || !this.props.user.preferredPharmacy) return null;

        return <>
        <Row>
          <Col sm={12} md={12}>
          <div className="input-label-text input-one-fourth-width">Preferred Pharmacy</div>
          <PharmacySelect
            pharmacyList={this.state.pharmacyList}
            onChange={this.handleChange.bind(this)}
            name="preferredPharmacy"
            selectedPharmacy={this.props.user.preferredPharmacy}
            isDeletable = {this.state.allowEdit}
            className={Util.applyInputStyles(this.state.validationErrors, "preferredPharmacy", [
              "form-control"
            ])}
          />
          </Col>
        </Row>
        
        </>
      }
    


    render() {

      if(this.state.redirectToDashboard){
        return(
          <Redirect to={'/dashboard'}></Redirect>
        )
      }
        return(
            <>
                <AlertDialog
                    onClose={this.closeAlert.bind(this)}
                    message={this.state.errorMessage}
                />
                <Modal
                    header="Family Doctor"
                    backdrop={'static'}
                    show={this.state.showFamilyDocModal}
                    setShow={this.toggleFamilyDocModal.bind(this)}
                    confirm={this.confirmFamilyDoc.bind(this)}
                    cancelFunc={this.cancelFamilyDoc.bind(this)}
                    message="Do you have a family doctor or nurse practitioner?"
                    confirmText="Yes"
                    cancelText="No"
                    closeButton={false}
                    keyboard={false}
                />

                <Modal
                    header="Please confirm your information"
                    show={this.state.isConfirmInfoModalShown}
                    confirm={this.confirmInfoModal.bind(this)}
                    confirmText="Information is correct"
                    message={this._modalText}
                    cancelFunc={this.editInfoFromModal.bind(this)}
                    cancelText="Edit my information"
                    backdrop={'static'}
                    setShow={this.setShowConfirmInfoModal.bind(this)}
                    closeButton={false}
                    keyboard={false}
                />
                <div id="account-container">
                    <Container>
                    <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
                      <Tab eventKey="profile" title="Profile">
                      <>
                      <h1 className="keywords">My Account</h1>
                        <Alert/>
                        <p className="">Use this page to update any of your personal information.</p>
                        <Row>
              <Col lg={3} md={6} sm={12}>
              <div className="input-full-width input-label-text">
              First Name
            </div>
                <input
                className={Util.applyInputStyles(
                    this.state.validationErrors,
                    "firstName",
                    ["input-full-width"]
                )}
                disabled={true}
                type="text"
                name="firstName"
                onChange={this.handleChange.bind(this)}
                value={this.state.items.firstName}
                placeholder=""
                />
              </Col>

             <Col lg={3} md={6} sm={12}>
             <div className="input-full-width input-label-text">
              Last Name
            </div>
            <input
              className={Util.applyInputStyles(
                this.state.validationErrors,
                "lastName",
                ["input-full-width"]
              )}
              disabled={true}
              type="text"
              name="lastName"
              onChange={this.handleChange.bind(this)}
              value={this.state.items.lastName}
              placeholder=""
            />
 
             </Col>
           <Col lg={3} md={6} sm={12}>
            <div className="input-full-width input-label-text">Gender</div>
            <select
              className={Util.applyInputStyles(
                this.state.validationErrors,
                "sex",
                ["input-full-width"]
              )}
              name="sex"
              value={this.state.items.sex}
              onChange={this.handleChange.bind(this)}
              disabled={this.state.allowEdit? false : true}
            >
              <option value=""></option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              {/* <option value="Transgendered">Transgendered</option> */}
              <option value="Other">Other</option>
            </select>
            </Col>
            <Col lg={3} md={6} sm={12}>
            <div className="input-full-width input-label-text">
              Date of Birth (YYYY-MM-DD)
            </div>
            <input
              className="input-full-width"
              type="text"
              name="dateOfBirth"
              value={this._getDateOfBirthVal()}
              disabled={true}
            />
            </Col>
            </Row>
            <Row>
              <Col lg={6} md={12} sm={12}>
              <div className="input-label-text input-full-width">Address</div>
              <input
              className={Util.applyInputStyles(
                this.state.validationErrors,
                "address",
                ["input-full-width"]
              )}
              type="text"
              name="address"
              onChange={this.handleChange.bind(this)}
              value={this.state.items.address}
              placeholder=""
              disabled={this.state.allowEdit? false : true}
            />
              </Col>
              <Col lg={6} md={12} sm={12}>
              <div className="input-label-text input-half-width">Email - To modify come in-person at the clinic and show picture ID</div>
                         
             <input className="input-full-width"
             type="text"
             name="email"
             value={this.state.items.email}
             disabled={true}
             />
              </Col>
            </Row>
            
            <Row>
              <Col lg={3} md={6} sm={12}>
            <div className="input-label-text input-full-width">City</div>
            <input
              className={Util.applyInputStyles(
                this.state.validationErrors,
                "city",
                ["input-full-width"]
              )}
              type="text"
              name="city"
              onChange={this.handleChange.bind(this)}
              value={this.state.items.city}
              placeholder=""
              disabled={this.state.allowEdit? false : true}
            />
            </Col>
            <Col lg={3} md={6} sm={12}>
            <div className="input-label-text input-one-fourth-width">Province</div>
            {/* <input
              className={Util.applyInputStyles(
                this.state.validationErrors,
                "province",
                ["input-full-width"]
              )}
              type="text"
              name="province"
              onChange={this.handleChange.bind(this)}
              value={this.state.province}
              placeholder=""
              disabled={this.state.allowEdit? false : true}
            /> */}
            <select
                 className={Util.applyInputStyles(this.state.validationErrors, "province", [
                   "input-full-width"
                 ])}
                 name="province"
                 
                 value={this.state.items.province}
                 onChange={this.handleChange.bind(this)}
                 disabled={this.state.allowEdit? false : true}
               >
                 <option value=""></option>
               {!this.state.isValidProvinceFound ?  <option value={this.state.items.province}>{this.state.items.province} - (update required*)</option> : null}
                 <option value="British Columbia">British Columbia</option>
                 <option value="Alberta">Alberta</option>
                 <option value="Manitoba">Manitoba</option>
                 <option value="Nunavut">Nunavut</option>
                 <option value="Nova Scotia">Nova Scotia</option>
                 <option value="New Brunswick">New Brunswick</option>
                 <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                 <option value="Northwest Territories">Northwest Territories</option>
                 <option value="Ontario">Ontario</option>
                 <option value="Prince Edward Island">Prince Edward Island</option>
                 <option value="Quebec">Quebec</option>
                 <option value="Saskatchewan">Saskatchewan</option>
                 <option value="Yukon">Yukon</option>
                 <option value="Other">Other</option>
               </select>
 
 
 
            </Col>
            <Col lg={3} md={6} sm={12}>
            <div className="input-label-text input-full-width">
              Postal Code
            </div>
            <input
              className={Util.applyInputStyles(
                this.state.validationErrors,
                "postal",
                ["input-full-width"]
              )}
              type="text"
              name="postal"
              onChange={this.handleChange.bind(this)}
              value={this.state.items.postal}
              placeholder=""
              disabled={this.state.allowEdit? false : true}
            />
            </Col>
            <Col lg={3} md={6} sm={12}>
            <div className="input-label-text input-full-width">
              Country
            </div>
            <CountryList 
              classStyle={Util.applyInputStyles(
                this.state.validationErrors,
                "country",
                ["input-full-width"]
              )}
              onChange={this.handleChange.bind(this)}
              name="country"
              defaultValue={this.state.items.country}
              disabled={this.state.allowEdit? false : true}
             />
            </Col>
            </Row>
 
            <Row>
              <Col md={4} sm={12}>
            <div className="input-full-width input-label-text">
              Mobile Phone
            </div>
            <input
              className={Util.applyInputStyles(
                this.state.validationErrors,
                "mobilePhone",
                ["input-full-width"]
              )}
              type="text"
              name="mobilePhone"
              onChange={this.handleChange.bind(this)}
              value={this.state.items.mobilePhone}
              placeholder=""
              disabled={this.state.allowEdit? false : true}
            />
            </Col>
            <Col md={4} sm={12}>
            <div className="input-full-width input-label-text">
              Home Phone
            </div>
            <input
              className={Util.applyInputStyles(
                this.state.validationErrors,
                "phone",
                ["input-full-width"]
              )}
              type="text"
              name="phone"
              onChange={this.handleChange.bind(this)}
              value={this.state.items.phone}
              placeholder=""
              disabled={this.state.allowEdit? false : true}
            />
 
            </Col>
            <Col md={4} sm={12}>
            <div className="input-full-width input-label-text">
              International Phone
            </div>
            <input
              className={Util.applyInputStyles(
                this.state.validationErrors,
                "internationalPhone",
                ["input-full-width"]
              )}
              type="text"
              name="internationalPhone"
              placeholder=""
              value={this.state.items.internationalPhone}
              onChange={this.handleChange.bind(this)}
              disabled={this.state.allowEdit? false : true}
            />
            </Col>
            </Row>
 
            <Row>
              <Col sm={12} lg={6} md={6}>
            {this.state.items.healthCardProvince?
              <div className="input-label-text input-full-width">
                Health Card Province
              </div>:null}
              {this.state.items.healthCardProvince?
              <input
              className="input-full-width"
              type="text"
              name="healthCardNumber"
              value={this.state.items.healthCardProvince}
              disabled={true}
            />:null}
              </Col>
              <Col sm={12} lg={6} md={6}>
            {this.state.items.healthCardNumber?
              <div className="input-label-text input-full-width">
                Health Card Number
              </div>:null}
              {this.state.items.healthCardNumber?
              <input
              className="input-full-width"
              type="text"
              name="healthCardNumber"
              value={this.state.items.healthCardNumber}
              disabled={true}
            />:null}
              </Col>
            </Row>

            {this.renderPreferedPharmacy()}
            
            <Row >
             <Col sm={12} md={3}>
                <button className="btn btn-uni mt-10p" id="my-1" onClick={this.toggleEdit.bind(this)} hidden={this.state.allowEdit? true : false}>
                    Edit
                </button>

                <button className="btn btn-uni mt-10p" onClick={this.updateData.bind(this)} hidden={this.state.allowEdit? false : true}>
                    Update
                </button>

             </Col>
             {/* <Col md={4}>&nbsp;</Col> */}
             {this.state.allowEdit ? 
             <Col md={{offset:6,span:3}} className="pull-right">
             <button className="btn btn-secondary mt-10p" onClick={()=>this.toggleEdit()} styles={['pull-right']}>
                   Cancel
             </button></Col>:null}
           </Row></>
                        
                      </Tab>
                      <Tab eventKey="password" title="Change Password">
                         {/* change password */}
                      <h1 className="keywords">Change Password</h1>
                      <p className="">Use this page to reset your password.</p>
                      <Row>
                        <Col md={6}>
                        <div className="input-label-text input-full-width">
                              Current Password*
                            </div>
                            <input
                          className={Util.applyInputStyles(
                            this.state.validationErrors,
                            "currentPassword",
                            ["input-full-width"]
                          )}
                          type="password"
                          name="currentPassword"
                          onChange={this.handlePasswordChange.bind(this)}
                          value={this.state.currentPassword}
                        />
                        </Col>
                      </Row>
                      <Row>
                          <Col><div className="input-label-text input-full-width">
                              New Password*
                            </div>
                            <input
                          className={Util.applyInputStyles(
                            this.state.validationErrors,
                            "passwordReset1",
                            ["input-full-width"]
                          )}
                          type="password"
                          name="passwordReset1"
                          onChange={this.handlePasswordChange.bind(this)}
                          value={this.state.passwordReset1}
                        />
                        </Col>
                        <Col><div className="input-label-text input-full-width">
                              Re-enter Password*
                            </div>
                            <input
                          className={Util.applyInputStyles(
                            this.state.validationErrors,
                            "passwordReset2",
                            ["input-full-width"]
                          )}
                          type="password"
                          name="passwordReset2"
                          onChange={this.handlePasswordChange.bind(this)}
                          value={this.state.passwordReset2}
                        /></Col>
                      </Row>
                      <Row>
                        <Col></Col>
                        <Col md={{offset:6}} className="pull-right">
                        <button className="btn btn-uni mt-10p" onClick={()=>this.resetPassword()} styles={['pull-right']}>
                              update password
                        </button></Col>
                      </Row>
                      </Tab>
                  </Tabs>
                        
                    </Container>
                
                </div>
                
            </>
        )
    }
}


const mapStateToProps = state => ({
    user:state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    pharmacy: state.pharmacy
})

const mapDispatchToProps = {
    loadUser,
    setUser,
    setShouldUpdate,
    setAlert,
    setToLoading,
    setToLoaded
};


export default connect(mapStateToProps,mapDispatchToProps)(MyAccount);

