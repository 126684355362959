

class InputValidationService {
    checkEmpty(input, key, errors) {
        if (input.length === 0) {
            errors.push(key);

            return false;
        }

        return true;
    }

    removeWhiteSpace(input){
      return input.trim();
    }

    checkUnnecessaryCharacters(input, key, errors){
      if(input.match(/[`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi)){
        errors.push(key);
      }
    }

    checkEqual(input1, input2, key, errors) {
        if (input1 !== input2 && !errors.includes(key)) {
            errors.push(key);

            return false;
        }

        return true;
    }

    checkLengthGreater(input, length, key, errors) {
        if (input.length < length && !errors.includes(key)) {
            errors.push(key);

            return false;
        }

        return true;
    }

    inputRequiresConfirmValidate(data,key1, key2, fixLength, errors) {
        if (
          !this.checkLengthGreater(
            data[key1],
            fixLength,
            key1,
            errors
          ) ||
          !this.checkLengthGreater(
            data[key2],
            fixLength,
            key2,
            errors
          ) ||
          !this.checkEqual(
            data[key1],
            data[key2],
            key1,
            errors
          )
        ) {
          if (!errors.includes(key1)) {
            errors.push(key1);
          }
        }
      }
}

export default new InputValidationService();