import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FileUploader from '../layout/file-uploader';


class FileUploaderController extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            fileName1:"",
            fileName2:"",
            fileName3:"",
            shouldClearButtonShow:false
        }

        this.fileRef1 = React.createRef();
        this.fileRef2 = React.createRef();
        this.fileRef3 = React.createRef();
    }

    componentDidMount() {
        console.log(this.fileRef1.current.value)
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.sendMessageObj!==this.props.sendMessageObj){
            if(this.props.isNoAttachment()){
                this.setState({
                    shouldClearButtonShow:false
                })
            }else{
                this.setState({shouldClearButtonShow:true})
            }
        }
    }

    get mandatoryFileNamePlaceHolder(){
        return "Choose file (mandatory)"
    }

    get optionalFileNamePlaceHolder(){
        return "Choose file (optional)"
    }



    setFileName(fileName,fileKey) {
        let name = fileName;
        if(name==="Choose file"){
            if(fileKey=='fileName1'){
                name=this.mandatoryFileNamePlaceHolder;
            }else {
                name=this.optionalFileNamePlaceHolder
            }
        }
        this.setState({ [fileKey]: name})
    }

    reset(){
        const {changeFile} = this.props;
        
        changeFile(undefined, 'file1')
        changeFile(undefined, 'file2')
        changeFile(undefined, 'file3')

        // this.setState({
        //     fileName:"Choose file"
        // })

        this.setFileName(this.mandatoryFileNamePlaceHolder,'fileName1')
        this.setFileName(this.optionalFileNamePlaceHolder,'fileName2')
        this.setFileName(this.optionalFileNamePlaceHolder,'fileName3')

        this.fileRef1.current.value = ''
        this.fileRef2.current.value = ''
        this.fileRef3.current.value = ''

    }


    render() {
        return(
            <>
                <FileUploader className="mb-2" multiple={this.props.multiple} onChange={e=>this.props.changeFile(e,'file1')} accept="application/pdf, image/*" disabled={this.props.isButtonDisabled} setFileName={this.setFileName.bind(this)} fileName={this.state.fileName1} name="fileName1" placeHolder={this.mandatoryFileNamePlaceHolder}
                setRef={this.fileRef1}/>
                
                <FileUploader className="mb-2" multiple={this.props.multiple} onChange={e=>this.props.changeFile(e,'file2')} accept="application/pdf, image/*" disabled={this.props.isButtonDisabled} setFileName={this.setFileName.bind(this)} fileName={this.state.fileName2} name="fileName2" placeHolder={this.optionalFileNamePlaceHolder} setRef={this.fileRef2}/>

                <FileUploader className="mb-2" multiple={this.props.multiple} onChange={e=>this.props.changeFile(e,'file3')} accept="application/pdf, image/*" disabled={this.props.isButtonDisabled} setFileName={this.setFileName.bind(this)} fileName={this.state.fileName3} name="fileName3" placeHolder={this.optionalFileNamePlaceHolder} setRef={this.fileRef3}/>
                
                {this.state.shouldClearButtonShow ? <button className="btn btn-outline-secondary mt-2" disabled={this.props.isButtonDisabled} onClick={e=>{e.preventDefault();this.reset()}}>Clear attachments</button> : null }
            </>
            
        )
    }

}

const mapStateToProps = (state)=>{
    let { sendMessageObj } = state.message;
    return {sendMessageObj}
}

export default connect(mapStateToProps) (FileUploaderController);
