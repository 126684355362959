

import { 
    AUTH_ERROR, 
    LOGOUT, 
    LOGIN_SUCCESS, 
    USER_LOADED, 
    RESET_PASSWORD, 
    UPDATE_PASSWORD,
    UPDATE_MESSAGE,
    USER_UPDATED,
    USER_FAMILY_DOC_SET,
    USER_FAILED_TO_CREATE_ACCOUNT } from "../actions/type";
  
  const initialState = {
    token: localStorage.getItem("accessToken"),
    isAuthenticated: null,
    loading: true,
    user: null,
    // isWorking: false,
    // isSent: false,
    // isSuccess: false,
    alertMessage: '',
  };
  
  export default (state=initialState,action) => {
  
    const {type,payload} = action;
    switch(type){
      case AUTH_ERROR:
        localStorage.removeItem('accessToken');
        return {
          ...state,
          isAuthenticated:false,
          loading:false,
          token:null,
          user:null,
          alertMessage: action.alertMessage ||''
        }
      case LOGOUT:
        localStorage.removeItem('accessToken');
        return {
          token:null,
          isAuthenticated:false,
          loading:false,
          token:null,
          user:null,
          alertMessage: ''
        }
      
      case LOGIN_SUCCESS:
        localStorage.setItem('accessToken',payload.token)
        return {
          ...state,
          isAuthenticated:true,
          loading:false
        }
  
      case USER_LOADED:
        return {
          ...state,
          user:payload,
          isAuthenticated:true,
          loading:false
        }
  
      // case RESET_PASSWORD:
      //   return {
      //     ...state,
      //     isWorking: false,
      //     isSent: action.status || false
      //   }
  
      // case UPDATE_PASSWORD:
      //   return {
      //     ...state,
      //     isWorking: false,
      //     alertMessage: action.alertMessage || '',
      //     isSuccess: true
      //   }
  
      case UPDATE_MESSAGE:
        return {
          ...state,
          alertMessage: action.alertMessage || '',
        }
      
      case USER_UPDATED:
        return {
          ...state,
          user: payload
        }
      case USER_FAMILY_DOC_SET:
        return {
          ...state,
          user:{
            ...state.user,
            familyDoc:payload,
            isChanged:state.user.isChanged==undefined ? true : !state.user.isChanged
          }
        }
  
      // case USER_FAILED_TO_CREATE_ACCOUNT:
      //   return {
      //     ...state,
      //     alertMessage: action.reason.message
      //   }
      default:
        return state;
    
    }
  }