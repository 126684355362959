import React from 'react';
import moment from 'moment'

import { messagesService } from '../services/messages';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {setAlert} from '../actions/alert';
import { messageAction } from '../actions/message.action';
import {connect} from 'react-redux';


import { history } from '../_helpers'

class Messages extends React.Component {

    constructor(props) {
        super();
        this.state = {
             messages : [],
             isnewOpen: false,
             message:"",
             isErrMes:false,
             limit:15,
             skip:0,
           

            }
    }

    async componentDidMount() {
        const { limit, skip} = this.state;
        const messages = await messagesService.getMessagesByPerson(limit, skip);
        this.setState({
            messages: messages
        })
    }

    async componentDidUpdate(prevProps, prevState) {
        try {
            if (prevState.skip != this.state.skip) {
                const { limit, skip } = this.state;
                const messages = await messagesService.getMessagesByPerson(limit, skip);
                this.setState({
                    messages: messages
                })
            }
        } catch (error) {
            console.log(error);
        }

    }

     nextPage = () => {
         const { skip, limit } = this.state;
         this.setState({
             skip: (skip + limit),
         })
    }

     previousPage = () => {
        const { skip, limit } = this.state;
        this.setState({
            skip: (skip-limit),
        })
    }


    handleCompose = () => {
        this.setState({
            isnewOpen: true,
        })
    }

    handleClose = () => {
        this.setState({
            isnewOpen: false,
            message:"",
        })
    }

    handleChange = event => {

        this.setState({
            [event.target.name] : event.target.value,
        })

        console.log(event.target.name, event.target.value)
    } 
    
    validate = () => {
        const { message } = this.state;
        const newMes = message.trim();

        if (newMes == ''){
            // this.props.setAlert('message can not be empty!', 'danger');
            this.setState({
                isErrMes:true,
            }) 
            return false;
        }

        this.setState({
            isErrMes:false,
        })

        return true;

    }



     handleSubmit = async (event) =>{
        event.preventDefault();

        if (!this.validate()) return;
    
        const {message } = this.state;
        this.setState({
            isnewOpen: false,
            message:""
        })
        await this.props.sentToMOA(message);

          
    }





   
    displayContent = () => {
         const { message, isErrMes} = this.state;
        return (
            <form className="" noValidate onSubmit={this.handleSubmit}>
                <div className="form-group">
                    {isErrMes && <p className="text-danger">Message can not be empty!</p>}
                    <label htmlFor="message">Message</label>
                    <textarea className="form-control" 
                              id="message" 
                              name="message" 
                              rows="4" 
                              placeholder="message ..." 
                              value={message} 
                              onChange={this.handleChange} 
                              maxlength="200"></textarea>
                </div>
                <br />
                <button type="submit" className="btn btn-primary mt-4">Send</button>
            </form>
        );
    }

    

    renderNewMessage = () =>{
        const { isnewOpen } = this.state;
        return (
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={isnewOpen}
                onClose={this.handleClose}
            >
                <DialogTitle className="no-print">
                    <span>Compose Message</span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                        <i className="fa fa-close mx-2"></i>
                    </button>
                </DialogTitle>
                <DialogContent>
                    {this.displayContent()}
                </DialogContent>
            </Dialog>
        )
    }



    render() {
        return (
            <div id="policies"  className="py-2">
                <div className='mb-2 ml-2'><h2> My Messages</h2></div>
                <div 
                    style={{display: "flex", justifyContent: "space-between"}}
                >
                    <button className="btn btn-secondary mb-5 ml-2" onClick={this.handleCompose}>
                        <i className="fas fa-plus"></i>
                        <span className="ml-2">Compose</span>
                    </button>
                </div>
                <div className='ml-5 mr-5'>
                    <table className="table">
                        <tr>
                            <th scope="col" className='col-3'>FROM</th>
                            <th scope="col">MESSAGE</th>
                            <th scope="col" className='col-3'>DATE</th>
                        </tr>
                        {(this.state.messages || []).map( m => {
                            
                            return <tr 
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push('message/' + m._id);
                                }}
                                className={
                                    m.repliedAt && m.repliedMessage ? "font-weight-normal" : "font-weight-bold" 
                                }
                                role="button"
                            >
                                <td>Medical Office Assistant</td>
                                <td>{m.message}</td>
                                <td>{moment(m.createdAt).format('MMM DD, YYYY')}</td>
                            </tr>
                        })}
                    </table>
                </div>
                <div> 
            <button className = "btn btn-primary" style= {{marginRight:"15px", marginLeft:"5px" }} onClick={this.previousPage}> Previous Page </button>
            <button className = "btn btn-primary" onClick={this.nextPage}> Next Page </button> 
        </div>
                <div>{this.renderNewMessage()}</div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    setAlert,
    sentToMOA: (message) => dispatch(messageAction.sentToMOA(message))
  }
}

export default connect(null, mapDispatchToProps)(Messages);
