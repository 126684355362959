import { SET_SICK_NOTE } from "../actions/type";

const initialState = {};

const sickNote = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SICK_NOTE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default sickNote;