import React from 'react';
import { scroller } from 'react-scroll';
import {Helmet} from 'react-helmet';

class IMMI extends React.Component {



    componentDidMount(){
        this._scrollToTop();
    }

    _scrollToTop(){
        scroller.scrollTo("immi", {
            duration: 800,
            smooth: true,
            offset: -100
        });
    }

    render() {
        return (
            <>
            <Helmet>
                    <title>Immigration medical exam | Richmond In-Store Medical Clinic | Vancouver doctor</title>
                    <link rel="canonical" href="https://instoremed.com/immigration" />
                    <meta name="description" content="Richmond In-Store Medical Clinic provides immigration medical exam"/>
                    <meta name="keywords" content="Immigration medical exam, New zealand immigration medical exam, canada immigartion medical exam, Immigration medical exam near me, Immigration medical exam frequently asked questions"/>

                </Helmet>

                <section id="immi" class="py-2">

<div class="floating-btn-container">
  <a class="btn btn-app" href="https://immigration.southvanmed.com/"> <i class="fab fa-canadian-maple-leaf"></i> Book Immigration Medical Exam</a>
</div>

<div class="container">


  <h1 class="center p-1">Price <span class="keywords">list</span></h1>
  <div class="bottom-line"></div>

  <div class="center">We require payment for booking immigration medical exams. The exception is if you are an asylum seeker (refugee). In that case, please email us at info@SouthVanMed.com. Please book immigration medical exams on our website <a class="file-url"
      href="https://immigration.southvanmed.com/">immigration.southvanmed.com</a>.</div>
 
  <div class="fee-item">
    <h3 class="mt-4 center bold">Medical Exam <span class="keywords">Fee </span></h3>
    <div class="bottom-line"></div>

    <h3 class="mt-4 center bold">CANADA</h3>

    <table class="table table-striped table-hover center">
      <thead>
        <tr>
          <th class="table-hover" scope="col">Age</th>
          <th class="table-hover" scope="col">Price</th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-hover">
          <td>0-74</td>
          <td>$250 <span class="note">including GST</span></td>
        </tr>
        <tr class="table-hover">
          <td>75+</td>
          <td>$325 <span class="note">including GST</span></td>
        </tr>

      </tbody>
    </table>

   

    <h3 class="mt-4 center bold">NEW ZEALAND</h3>

    <table class="table table-striped table-hover center">
      <thead>
        <tr>
          <th class="table-hover" scope="col">Age</th>
          <th class="table-hover" scope="col">Price</th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-hover">
          <td>0-69</td>
          <td>$300 <span class="note">including GST</span></td>
        </tr>
        <tr class="table-hover">
          <td>70+</td>
          <td>$375 <span class="note">including GST</span></td>
        </tr>

      </tbody>
    </table>

  </div>

  <div class="fee-item">
    <h3 class="mt-4 center bold">Xray <span class="keywords">Fees</span></h3>
    <div class="bottom-line"></div>
    <span class="note">*Children 10 years old and younger usually do not require a chest X-ray</span>
  <table class=" table table-striped table-hover center">
      <thead>
        <tr class="table-hover">
          <th scope="col">Item</th>
          <th scope="col">Price</th>
          <th scope="col">Location</th>
        </tr>
      </thead>
      <tbody>
        {/* <tr class="table-hover">
          <td>Brooke Radiology</td>
          <td>$100</td>
          <td>Room 150, 5791 No. 3 Road, Richmond</td>
        </tr> */}

        <tr class="table-hover">
          <td>Greig Associates</td>
          <td>$90</td>
          <td>Oakridge Mall, 219-650 West 41st Avenue, Vancouver</td>
        </tr>

        <tr class="table-hover">
          <td>Greig Associates</td>
          <td>$90</td>
          <td>5732 Victoria Drive, Vancouver</td>
        </tr>

        <tr class="table-hover">
          <td>West Coast Medical Imaging</td>
          <td>$80</td>
          <td>Laurel Medical Imaging 106-888 W 8th Avenue, Vancouver</td>
        </tr>

      </tbody>
      </table>
  </div>

  <div class="fee-item">
    <h3 class="mt-4 center bold">Lab Tests (performed at LifeLabs, see <a class="keywords" href="https://www.lifelabs.com/">https://www.lifelabs.com/</a> )</h3>
    <span class="note">*Children 14 years old and younger usually do not require a blood test but may require a urine test</span>
    <table class="table table-striped table-hover center">
      <thead>
        <tr class="table-hover">
          <th>Item</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-hover">
          <td>Lab Tests</td>
          <td>$80</td>
        </tr>
      </tbody>
    </table>
    <div>
      <p class="note"> <strong>*Laboratory testing fees may vary based on the required blood and urine tests.
        </strong></p>
    </div>
  </div>

   {/* <div class="bottom-line-long"></div>  */}

  <div id="faq" class="imm-faq">
    <h3 class="center p-1">Immigration medical exam <span class="keywords">frequently asked questions (FAQ):</span></h3>
    <div class="bottom-line"></div>
    <div class="row py-2">
      <div class="col-lg-12">
        <div id="bootstrap-accordion_12" class="panel-group accordionStyles accordion" role="tablist"
          aria-multiselectable="true">
          <div class="row">
            <div class="col-lg-6">

              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse1_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">1. What do I need to bring to the immigration medical
                      exam?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse1_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                      1.Original passport or other official identification document. This is mandatory, a photocopy will not be sufficient.
                    </p>
                    <p class="mbr-fonts-style panel-text  display-4"> 2.Your proposed visa application information, if available.</p>
                    <p class="mbr-fonts-style panel-text  display-4"> 3.Documents issued by your immigration authority (for example, the IMM 1017 form for Canada).</p>
                    <p class="mbr-fonts-style panel-text  display-4"> 4.Your glasses or wear contact lenses, as we will be checking your vision.</p>
                    <p class="mbr-fonts-style panel-text  display-4"> 5.Details of the prescription medication(s) you are currently taking.</p>
                    <p class="mbr-fonts-style panel-text  display-4"> 6.Bring completed and signed paper copies of the Medical History Questionnaire that will be emailed to you.</p>
                  </div>

                </div>

              </div>

              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse2_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">2. How long will the exam take?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse2_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                      It normally takes 20 to 30 minutes. However, if you have complex medical conditions, the examination may take longer.
                    </p>
                  </div>

                </div>

              </div>

              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse3_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">3. What are the steps for the exam at our clinic?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse3_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                      1. Register at the front desk, confirm your identity and take your picture.
                    </p>
                    <p class="mbr-fonts-style panel-text  display-4">
                      2. Obtain your medical history.
                    </p>
                    <p class="mbr-fonts-style panel-text  display-4">
                      3. Measure your height and weight, examine your eyes and  take your blood pressure.
                    </p>
                    <p class="mbr-fonts-style panel-text  display-4">
                      4. The doctor performs a physical examination.
                    </p>
                    <p class="mbr-fonts-style panel-text  display-4">
                      5. The doctor provides you requisitions such as for lab testing and X-rays. 
                    </p>
                    

                  </div>

                </div>

              </div>

              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse4_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">4. What are the steps after the exam at our clinic?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse4_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                    Our staff and your doctor will provide detailed instruction as to what needs to be done after your exam. You may need to complete lab testing at a LifeLabs location or get your chest X-ray taken. Most of the time, there is no need for you to return to the clinic after your immigration exam. Results are sent to us by LifeLabs and the X-ray facility directly.
                    </p>
                    {/* <p class="mbr-fonts-style panel-text  display-4">
                      2. Complete required lab testing at a LifeLabs location.
                    </p> */}
                  </div>

                </div>

              </div>

              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse5_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">5. Where do I go for my chest X-ray and lab tests?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse5_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                      1. X-ray locations: we recommend Greig Associates <a class="keywords" href="https://greigassociates.com/">https://greigassociates.com/</a>
                    </p>
                    <p class="mbr-fonts-style panel-text  display-4">
                      2. Lab test locations: LifeLabs <a class="keywords" href="https://www.lifelabs.com/">https://www.lifelabs.com/</a> 
                    </p>
                  </div>

                </div>

              </div>

              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse6_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">6. Do I need to fast before my lab tests?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse6_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                      No, but remember to go for your lab testing when you are not having a menstrual period if a urine test is required.
                    </p>
                    
                  </div>

                </div>

              </div>

              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse7_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">7. Do I need an appointment for my chest X-ray and lab tests?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse7_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                    Appointments are not required at Greig Associates <a class="keywords" href="https://greigassociates.com/">https://greigassociates.com/</a> or some LifeLabs locations <a class="keywords" href="https://www.lifelabs.com/">https://www.lifelabs.com/</a>. Please note that other X-ray and most LifeLabs locations require appointments.

                      
                    </p>
                  </div>

                </div>

              </div>

              {/* <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse8_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">8. What are the lab tests performed at Lifelabs?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse8_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                      1. Syphilis - applicants aged 15 years and older
                    </p>
                    <p class="mbr-fonts-style panel-text  display-4">
                      2. HIV - applicants aged 15 years and older
                    </p>
                    <p class="mbr-fonts-style panel-text  display-4">
                      3. Serum creatinine (kidney function test) - applicants aged 15 years and older
                    </p>
                    <p class="mbr-fonts-style panel-text  display-4">
                      4. Urine test for glucose, sugar and blood
                    </p>
                    <p class="mbr-fonts-style panel-text  display-4">
                      5. Additional tests may be required depending on the medical findings during the examination
                    </p>
                    <p class="mbr-fonts-style panel-text  display-4">
                      6. Lab testing should only be done when you are not having a menstrual period
                    </p>
                  </div>

                </div>

              </div> */}


            </div>
            
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse9_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">8. X-ray fee
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse9_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                      Each radiology clinic has its own fee. They generally charge between $85 and $100. Greig Associates <a class="keywords" href="https://greigassociates.com/">https://greigassociates.com/</a> charges $85 for the chest X-ray.
                    </p>
                    
                  </div>

                </div>

              </div>

              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse10_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">9. How long will it take to submit my medical report?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse10_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                      Once we receive your lab tests and chest X-ray results, we will submit your completed file to the relevant immigration authority within three (3) business days. Please email us at <span class="file-url">info@southvanmed.com</span> at least five (5) business days after completing both your blood tests and chest X-rays, and we will confirm whether your medical report has been submitted.

                    </p>
                    
                  </div>

                </div>

              </div>

              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse11_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">10. Cancellation and Rescheduling policy for immigration medical examinations
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse11_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                     1. Immigration Medical Examinations are absolutely non-refundable. If you cancel your appointment, we will not refund you any money.
                    </p>

                    <p class="mbr-fonts-style panel-text  display-4">
                      2. You can reschedule your appointment up to 24 hours prior to the time of your appointment time. Within 24 hours of your appointment, you may no longer reschedule your appointment.
                     </p>
                    
                     <p class="mbr-fonts-style panel-text  display-4">
                      3. All rescheduling of appointments must be done through our online booking system. We do not reschedule appointments by phone or email. 
                     </p>
                  </div>

                </div>

              </div>

              {/* <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse12_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">12. How long is my immigration medical exam valid for?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse12_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                      12 months
                    </p>
                    
                  </div>

                </div>

              </div> */}

              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse13_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">11. What should I do if I have more questions?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse13_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                      Please email us at <span class="file-url">info@southvanmed.com</span>
                    </p>
                    
                  </div>

                </div>

              </div>


              {/* <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse14_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">14. What are the medical history questions?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse14_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text display-4">
                      Please see the table below for the questions that you will need to answer: 
                      <ul class="list-group">
                        <li class="list-group-item">1. Tuberculosis (TB) or treatment for tuberculosis?</li>
                        <li class="list-group-item">2. Close household contact with tuberculosis (TB)?</li>
                        <li class="list-group-item">3. Prolonged medical treatment and/or repeated hospital admissions for any reason, including a major operation or psychiatric illness?</li>
                        <li class="list-group-item">4. Psychological/Psychiatric disorder (including major depression, bipolar disorder or schizophrenia)?</li>
                        <li class="list-group-item">5. An abnormal or reactive HIV blood test?</li>
                        <li class="list-group-item">6. An abnormal or reactive Hepatitis B or Hepatitis C blood test? </li>
                        <li class="list-group-item">7. Cancer or Malignancy in the last 5 years? </li>
                        <li class="list-group-item">8. Diabetes?</li>
                        <li class="list-group-item">9. Heart condition including coronary disease, hypertension, valve or congenital disease?</li>
                        <li class="list-group-item">10. Blood condition (including thalassemia)?</li>
                        <li class="list-group-item">11. Kidney or Bladder disease?</li>
                        <li class="list-group-item">12. An ongoing physical or intellectual disability affecting your current or future ability to function independently or be able to work full-time (including autism or developmental delay)?</li>
                        <li class="list-group-item">13. An addiction to drugs or alcohol?</li>
                        <li class="list-group-item">14. Are you taking any prescribed pills or medication (excluding oral contraceptives, over-the counter medication and natural supplements)?  If Yes, please list below:
                        </li>

                      </ul>
                    </p>
                    
                  </div>

                </div>

              </div> */}

              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse15_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">12. What happens if I am pregnant and I do not want to get the X-ray requested by Immigration, Refugees and Citizenship Canada (IRCC) done during my pregnancy?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse15_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                    If you choose to defer the chest X-rays because of pregnancy, we will provide you with a Pregnancy Deferral Letter to be sent to IRCC. We will also inform IRCC when chest X-rays are deferred. The health case is put on hold when an applicant is pregnant and chooses to defer their chest X-rays. The immigration medical exam will be submitted to IRCC along with the chest X-rays results when they are available for assessment by the regional medical office.
                    </p>
                    
                  </div>

                </div>

              </div>


              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                    data-core="" href="#collapse16_21" aria-expanded="false" aria-controls="collapse1">

                    <h4 class="mbr-fonts-style display-7">13. Do you charge an extra fee if Immigration,Refugees and Citizenship Canada requires a further exam?
                      <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                      </div>
                    </h4>
                  </a>
                </div>
                <div id="collapse16_21" class="panel-collapse noScroll collapse " role="tabpanel"
                  aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                  <div class="panel-body">
                    <p class="mbr-fonts-style panel-text  display-4">
                    Yes, we charge $200 if a further examination is requested by any of the above parties.
                    </p>
                    
                  </div>

                </div>

              </div>


            </div>


          </div>

        </div>


      </div>
    </div>
  </div>



</div>

</section>

            </>
        )
    }
}

export default IMMI;
