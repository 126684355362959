import {
    MEDICAL_RECORD_DOWNLOAD_TOKEN_INVALID,
    MEDICAL_RECORD_DOWNLOAD_TOKEN_VALID,
    MEDICAL_RECORD_GET_FILE_LIST_SUCCESS
} from '../actions/type';

const initialState = {
    isDownloadTokenValid: false,
    isLoading:true,
    fileList:[]
};



export default (state = initialState, action) => {

    const { type, payload} = action;
    switch (type) {
        
        case MEDICAL_RECORD_DOWNLOAD_TOKEN_INVALID:
            return {
                ...state,
                isLoading:false,
                isDownloadTokenValid:false
            }
        case MEDICAL_RECORD_DOWNLOAD_TOKEN_VALID:
            return {
                ...state,
                isLoading:false,
                isDownloadTokenValid:true
            }
        case MEDICAL_RECORD_GET_FILE_LIST_SUCCESS:
            return {
                ...state,
                fileList:payload
            }


        default:
            return state;

    }
}