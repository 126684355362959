import {combineReducers} from "redux";
import loader from './loader';
import alert from './alert';
import auth from './auth';
import lang from './lang';
import {medicalInfo} from './medicalInfo';
import sickNote from "./sick-note";
import pharmacy from './pharmacy';
import message from './message';
import medicalRecord from './medicalRecord';

export default combineReducers({
    loader,
    alert,
    auth,
    lang,
    medicalInfo,
    sickNote,
    pharmacy,
    message,
    medicalRecord
})