import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {logoutAction} from '../actions/auth';


class Logout extends React.Component {


    componentDidMount() {
        const { logoutAction} = this.props;
        logoutAction();
        // history.push('/login');
    }

    render() {
        return (
            <Redirect to='/login' />
        )
    }


}




const mapDispatchToProps = {
    logoutAction
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    auth:state.auth
});

export default connect(mapStateToProps,mapDispatchToProps)(Logout);