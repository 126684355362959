import React from 'react';
import { scroller } from 'react-scroll';
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet';


class BookAppointment extends React.Component {

    componentDidMount() {
        this._scrollToTop();
    }

    _scrollToTop() {
        scroller.scrollTo("appiontment", {
            duration: 800,
            smooth: true,
            offset: -100
        });
    }


    render() {
        return (
            <>
                <Helmet>
                    <title>Book Appointment | Richmond In-Store Medical Clinic | Vancouver doctor</title>
                    <link rel="canonical" href="https://instoremed.com/bookappointment" />
                    <meta name="description" content="Richmond In-Store Medical Clinic offers various of type of appointment, book one with us today."/>
                    <meta name="keywords" content="Immigration medical exam, In-person appointment, telephone appointment, Driver medical exam, ICBC appointment, Tourists and visitors from outside of Canada, international student appointment, Foreign workers appointment, quebec resident medical appointment"/>

                </Helmet>

                <section id="appiontment" className="py-2">
                    <div className="container">

                        <h1 className="center p-1">Book Appointment</h1>
                        <div className="bottom-line"></div>

                        <div className="appiontment-container">
                            <article className="card">
                                <div className="p-2">
                                    <h2 className="center py-1">In-Person or Phone Family Doctor Appointment</h2>
                                    <div className="bottom-line"></div>
                                    <p class="font-it">In Person Doctor visit for patients with a valid B.C.Services Card or equivalent Canadian health card</p>
                                    <div className="py-1">
                                        <h3 className="py-1">Important rules regarding In-Person or Phone Family Doctor Appointment
                                        </h3>
                                        <p class="py-1">1. EVERYONE can book an online appointment,even patients who have never been to our clinic.</p>
                                        <p class="py-1">2. You may cancel or reschedule your online appointment up to 6 hours in advance. To do so, click on the
                                        button below, log in to your account and click on <span class="important-text">My Appointments</span>.</p>
                                        <p class="py-1">3. Same day (within 6 hours) appointments <span class="important-text">CANNOT</span> be cancelled or rescheduled.
                                        Not showing up results in a $50.00 charge ($100.00 for specialist appointments). Multiple no shows restrict you from booking appointments at our clinic.</p>
                                        <p class="py-1">4. Our front desk <span class="important-text">DOES NOT </span> have the ability to cancel
                                        or reschedule online appointments. Therefore, please do not call or email our front desk to cancel or reschedule an appointment booked
                                        online.</p>
                                        <p class="py-1">5. If you wish to book a general surgery appointment with Dr. Kathy Lee, please do not use this booking system but instead email us at <i>info@instoremed.com</i>.</p>

                                        <div className="book-btn mt-1">
                                            <Link to="/login" className="btn btn-app">BOOK YOUR IN-PERSON OR PHONE APPOINTMENT</Link>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>

                    </div>
                </section>

            </>
        )
    }
}

export default BookAppointment;