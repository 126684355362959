import React from 'react'

export default class SpinningControl extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        // console.log(this.props.text)
        return <div id='spinner'>
                <div id="SpinningControl">
                </div><span id="spinner-text">{'Please be patient. This may take a while.'}</span>
            </div>
    }
}