import React from 'react';
import {connect} from 'react-redux';
import TermsModal from './TermsModal'
import PrivacyPolicyModal from './PrivacyPolicyModal'
import FeeScheduleModal from './FeeScheduleModal';

class TermsPrivacyFee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showTerms:false,
            showPrivacyPolicy:false,
            showFeeSchedule:false
        }
    }

    footerClick(e, args, hyperLink,item) {
        console.log('footerClick')
        if (!e) {
            return this.setState({
                showTerms: false,
                showPrivacyPolicy: false,
                showFeeSchedule: false
            })
        }

        let key = e.target.name;

        if(hyperLink){

                this.setState({
                    [item]:false
                },()=>{
                    this._modalHandler(key,args)
                })
         return;
        }
        this._modalHandler(key,args)
    }

    _modalHandler(key,args){
        switch (key) {
            case 'terms':
                // show terms
                this.setState({
                    ...this.state,
                    showTerms: args
                })
                break;
            case 'privacy-policy':
                //show policy
                this.setState({
                    ...this.state,
                    showPrivacyPolicy: args
                })
                break;
            case 'fee-schedule':
                // fee schedule
                this.setState({
                    ...this.state,
                    showFeeSchedule: args
                })
                break;
            default:
                this.setState({
                    showTerms: false,
                    showPrivacyPolicy: false,
                    showFeeSchedule: false
                })
        }
    }


    render (){
        return(
            <>
               
                    <TermsModal
                        show={this.state.showTerms}
                        footerClick={this.footerClick.bind(this)}
                    />
                    
                    <PrivacyPolicyModal
                        show={this.state.showPrivacyPolicy}
                        footerClick={this.footerClick.bind(this)}
                    />
                    
                    <FeeScheduleModal 
                        show={this.state.showFeeSchedule}
                        footerClick={this.footerClick.bind(this)}
                    />

                <div className="terms-privacy-fee-button-container">
                        <button className="button-link" onClick={e => this.footerClick(e, true)} name="terms">Terms of Use</button>
                        <button  className="button-link" onClick={e => this.footerClick(e, true)} name="privacy-policy">Privacy Policy</button>
                        <button className="button-link" onClick={e => this.footerClick(e, true)} name="fee-schedule">Fee Schedule</button>
                </div>
            </>
        )
    }
}

export default TermsPrivacyFee;